import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import DeleteIcon from './delete.png';
import { getJobs, deleteJob } from './requests';
import { errorNotification } from '../VideoPlayer/ExternalComponents/Notifications';

const MainContainer = styled.div`
  background: #eff3ff;
  height: 100vh;
  padding: 2rem;
`;

const MainText = styled.p`
  color: black;
  font-size: 17px;
  text-align: center;
`;

const Heading = styled.h2`
  color: black;
  font-size: 18px;
  font-weight: lighter;
  padding-top: 2rem;
  margin-bottom: 1rem;
  text-align: center;
`;

const Table = styled.table`
  font-size: 15px;
  color: black;
  border-collapse: collapse;
  min-width: 40%;
  margin: auto;

  tr {
    :hover .delete-btn {
      display: block;
    }
  }

  td,
  th {
    border-radius: 4px;
    min-width: 3.4rem;
    max-width: 25rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    background: white;
    border-bottom: 2px solid #f5f5f5;
    text-align: left;
    padding: 8px;
  }
`;

const DeleteButton = styled.button`
  display: none;
  height: 17px;
  border: 0;
  background-color: transparent;
  width: 17px;
  background: url(${DeleteIcon});
  background-size: 17px 17px;
  background-repeat: no-repeat;
  opacity: 0.7;

  :focus {
    outline: 0;
  }
`;

function Stats(props) {
  const [loading, setLoading] = useState(false);
  const [scanJobs, setScan] = useState(null);
  const [redacterJobs, setRedacter] = useState(null);
  const [writerJobs, setWriter] = useState(null);

  useEffect(() => {
    fetchJobs();
  }, []);

  async function fetchJobs() {
    setLoading(true);
    const fetchRes = await getJobs();
    setLoading(false);

    if (fetchRes.status !== 200) {
      errorNotification('Unable to fetch the data at the moment.Please check your internet connection!');
      return;
    }

    if (fetchRes.scanJobs && fetchRes.scanJobs.length > 0) {
      setScan(fetchRes.scanJobs);
    }

    if (fetchRes.redacterJobs && fetchRes.redacterJobs.length > 0) {
      setRedacter(fetchRes.redacterJobs);
    }

    if (fetchRes.writerJobs && fetchRes.writerJobs.length > 0) {
      setWriter(fetchRes.writerJobs);
    }
  }

  async function handleDeleteJob(qname, jobID) {
    const deleteRes = await deleteJob(qname, jobID);

    if (deleteRes.status !== 200) {
      errorNotification('Unable to delete job.Please try again later');
      return;
    }

    fetchJobs();
  }

  if (loading) {
    return <MainText>Loading...</MainText>;
  }

  if (!loading && !scanJobs && !redacterJobs && !writerJobs) {
    return <MainText>No data found</MainText>;
  }

  return (
    <MainContainer>
      {scanJobs && scanJobs.length > 0 && (
        <React.Fragment>
          <Heading>Scan Jobs</Heading>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Progress</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {scanJobs.map((data) => (
                <tr key={data.id}>
                  <td>{data.id}</td>
                  <td>{data.sProgress}</td>
                  <td>
                    <DeleteButton
                      className="delete-btn"
                      title="Delete"
                      onClick={() => handleDeleteJob('videoscan', data.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </React.Fragment>
      )}

      {redacterJobs && redacterJobs.length > 0 && (
        <React.Fragment>
          <Heading>Redact Jobs</Heading>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Progress</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {redacterJobs.map((data) => (
                <tr key={data.id}>
                  <td>{data.id}</td>
                  <td>{data.rProgress}</td>
                  <td>
                    <DeleteButton
                      className="delete-btn"
                      title="Delete"
                      onClick={() => handleDeleteJob('videoredacter', data.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </React.Fragment>
      )}

      {writerJobs && writerJobs.length > 0 && (
        <React.Fragment>
          <Heading>Write Jobs</Heading>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Progress</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {writerJobs.map((data) => (
                <tr key={data.id}>
                  <td>{data.id}</td>
                  <td>{data.wProgress}</td>
                  <td>
                    <DeleteButton
                      className="delete-btn"
                      title="Delete"
                      onClick={() => handleDeleteJob('videowriter', data.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </React.Fragment>
      )}
    </MainContainer>
  );
}

export default Stats;
