import React, { Component } from 'react';
import $ from 'jquery';
import { Popover, PopoverHeader, PopoverBody, Form, FormGroup, Label, Input } from 'reactstrap';

import { ButtonImage } from '../index';
import audioRedaction from '../../../Icons/audio-redaction.svg';
import { host } from '../../../config/globals';
import path from 'path';
import { warningNotification } from '../ExternalComponents/Notifications';
import { fetchMutedRegions, saveMutedRegions } from '../utils/axiosRequests';

const { formattedTimeSMPTE } = require('../HelperComponents/toSMPTE');

class Popoverbody extends Component {
  componentDidMount() {
    document.getElementById('audio-end-redact-region').value = formattedTimeSMPTE(
      this.props.num_frames,
      this.props.fps,
    );
    const start = document.getElementById('audio-start-redact-region');
    const end = document.getElementById('audio-end-redact-region');

    start.addEventListener('keypress', this.stopPropagation);
    start.addEventListener('keydown', this.stopPropagation);

    end.addEventListener('keypress', this.stopPropagation);
    end.addEventListener('keydown', this.stopPropagation);
  }

  stopPropagation = (e) => {
    e.stopPropagation();
    e.stopImmediatePropagation();
    e.cancelBubble = true;
  };
  timeToSeconds = (time) => {
    let totalSeconds = 0;
    const array = time.split(':');

    const hour = array[0] * 3600;
    totalSeconds = hour;

    const minutes = array[1] * 60;
    if (minutes === undefined || isNaN(minutes)) {
      return totalSeconds;
    }

    totalSeconds = Number(hour) + Number(minutes);

    const seconds = array[2];
    if (seconds === undefined) {
      return totalSeconds;
    }

    const mSec = seconds.split('.');
    totalSeconds = totalSeconds + Number(mSec[0]);

    if (mSec[1] === undefined) {
      return totalSeconds;
    }

    totalSeconds = totalSeconds + Number(mSec[1] / 100);
    return totalSeconds;
  };

  render() {
    return (
      <PopoverBody>
        <Form>
          <FormGroup>
            <Label className="label" for="audio-start-redact-region">
              Start:
            </Label>
            <Input type="text" id="audio-start-redact-region" defaultValue="0:00:00.00" />
          </FormGroup>
          <FormGroup>
            <Label className="label" for="audio-end-redact-region">
              End:
            </Label>
            <Input type="text" id="audio-end-redact-region" />
          </FormGroup>
          <FormGroup>
            <Label className="label">Format:</Label>
            <span style={{ fontSize: '12px' }}>H:MM:SS.FF </span>
          </FormGroup>
        </Form>
        <button
          type="button"
          className="btn btn-default btn-block"
          id="redact-button"
          onClick={() => {
            const start = this.timeToSeconds(document.getElementById('audio-start-redact-region').value);
            const end = this.timeToSeconds(document.getElementById('audio-end-redact-region').value);
            const videoDuration = document.getElementById('video').duration;
            if (start >= 0 && start < videoDuration && end > start && end <= videoDuration) {
              this.props.wavesurfer.addRegion({
                start,
                end,
                color: 'rgba(254,10,50,0.2)',
              });
              this.props.mergeAudio();
            } else if (start < 0) {
              warningNotification('Start time must be greater or zero');
            } else if (end < 0) {
              warningNotification('End time must be positive');
            } else if (start >= videoDuration) {
              warningNotification('Start time must be less than the end time');
            } else if (end > videoDuration) {
              warningNotification('End time must be less than or equal to video length');
            }
          }}
        >
          Redact
        </button>
      </PopoverBody>
    );
  }
}

export default class ToolbarRedactAudio extends Component {
  state = {
    popover: false,
    waveRegions: [],
    isVideoLoaded: this.props.is_video_loaded,
    disableRedactButton: true,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.is_video_loaded) this.setState({ isVideoLoaded: true });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.is_video_loaded !== this.state.isVideoLoaded) {
      this.metadataDir = this.props.metadataLoc;
      this.audio_src = path.join(this.metadataDir, 'audio.mp3');
      this.loadRegions();
    }
  }

  toggle = () => {
    this.setState({ popover: !this.state.popover });
  };

  saveAudio = async () => {
    const keys = Object.keys(this.props.wavesurfer.regions.list);
    const audioData = keys.map((region) => ({
      id: this.props.wavesurfer.regions.list[region].id,
      start: this.props.wavesurfer.regions.list[region].start,
      end: this.props.wavesurfer.regions.list[region].end,
    }));

    const saveRegionsRes = await saveMutedRegions(this.props.jobId, audioData);
    if (saveRegionsRes.status !== 200) {
      console.log('Failed to save muted regions');
    }
  };

  mergeAudio = () => {
    const keys = Object.keys(this.props.wavesurfer.regions.list);
    const arr = keys.map((region) => {
      const innerArray = [];
      innerArray.push(this.props.wavesurfer.regions.list[region].start);
      innerArray.push(this.props.wavesurfer.regions.list[region].end);
      return innerArray;
    });

    const result = arr.slice().sort((a, b) => a[0] - b[0]);

    let i = 0;
    while (i < result.length - 1) {
      const current = result[i];
      const next = result[i + 1];

      // check if there is an overlapping
      if (current[1] >= next[0]) {
        current[1] = Math.max(current[1], next[1]);
        // remove next
        result.splice(i + 1, 1);
      } else {
        // move to next
        i++;
      }
    }
    this.props.wavesurfer.clearRegions();
    // eslint-disable-next-line
    result.map((region) => {
      this.props.wavesurfer.addRegion({
        start: region[0],
        end: region[1],
        color: 'rgba(254,10,50,0.2)',
      });
    });

    this.saveAudio();
  };

  loadAudio = async () => {
    if (!this.props.videoMetadata.hasaudio) {
      $(
        "<div style='position:absolute;height:130px;width:500px'><div style='top:30%;left:30%;position:absolute; font-size: 2.7rem; color: #9f9f9f;'>No audio track found<br/></div></div>",
      ).prependTo($('#waveform'));

      $('#wavesurfer-zoom-buttons').prop('disabled', true).css({ display: 'none' });
      $('#waveform').prop('disabled', true).css({ pointerEvents: 'none' });
      $('#wave-timeline').prop('disabled', true).css({ display: 'none' });
      $('#toolbar-redact-audio-button').prop('disabled', true);

      $('#volume-bar').prop('disabled', true).css({ display: 'none' });
      $('#volume-button').prop('disabled', true).css({ display: 'none' });
      this.setState({ disableRedactButton: true });

      return;
    }

    $('#volume-bar').css({ display: 'block' });
    $('#toolbar-redact-audio-button').prop('disabled', false).css({ opacity: 1 });

    const audioUrl = new URL(`${host()}/suspect/static${this.metadataDir}/audio.mp3`);
    await this.props.wavesurfer.load(audioUrl);

    const loadingAudio = $(
      "<div style='position:absolute;height:130px;width:500px'><div style='top:30%;left:30%;position:absolute; font-size: 2.7rem; color: #9f9f9f;'>Loading audio...<br/></div></div>",
    ).prependTo($('#waveform'));

    this.props.wavesurfer.on('ready', async () => {
      const sliderbar = document.getElementById('seek-bar');
      this.props.wavesurfer.seekTo(sliderbar.value / 100);

      const videoPlayer = document.getElementById('video');
      if (!videoPlayer.paused) {
        this.props.wavesurfer.play();
      }

      // eslint-disable-next-line
      this.state.waveRegions.map((data) => {
        this.props.wavesurfer.addRegion({
          id: data.id,
          start: data.start,
          end: data.end,
          color: 'rgba(254,10,50,0.2)',
        });
      });
      this.props.wavesurfer.enableDragSelection({
        color: 'rgba(254,10,50,0.2)',
      });
      loadingAudio.remove();
    });
    this.props.wavesurfer.on('region-in', (e) => {
      this.props.wavesurfer.setVolume(0);
    });
    this.props.wavesurfer.on('region-out', () => {
      const volumeBar = document.getElementById('volume-bar');
      this.props.wavesurfer.setVolume(volumeBar.value);
    });
    this.props.wavesurfer.on('region-created', (region) => {
      console.log('Created', region);

      $('.wavesurfer-handle-end').css({ height: '80%', top: '20%' });

      const regionElement = region.element;

      region.on('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
      });

      const closeButton = document.createElement('div');
      closeButton.setAttribute('id', region.id);
      closeButton.setAttribute('class', 'closeButton');
      closeButton.style.float = 'right';
      closeButton.style.top = '0';
      closeButton.style.cursor = 'pointer';
      closeButton.style.background =
        'linear-gradient(-45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%),linear-gradient(45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%)';
      closeButton.style.width = '20px';
      closeButton.style.height = '20px';
      closeButton.style.borderWidth = '2px';
      closeButton.style.borderStyle = 'solid';
      closeButton.style.borderRadius = '100%';
      closeButton.style.backgroundColor = 'red';
      closeButton.style.boxShadow = '0px 0px 5px 2px rgba(0,0,0,0.5)';
      closeButton.style.transition = 'all 0.3s ease';
      closeButton.style.display = 'none';
      closeButton.style.boxSizing = 'border-box';
      closeButton.style.borderColor = 'red';
      closeButton.style.zIndex = '100';
      closeButton.style.display = 'none';

      regionElement.appendChild(closeButton);
    });
    this.props.wavesurfer.on('region-mouseenter', (e) => {
      $(`[id=${e.id}]`).css('display', 'block');
      $(`[id=${e.id}]`).click(() => {
        e.remove();
        const volumeBar = document.getElementById('volume-bar');
        this.props.wavesurfer.setVolume(volumeBar.value);
        this.saveAudio();
      });
    });

    this.props.wavesurfer.on('region-update-end', () => {
      this.mergeAudio();
    });

    this.props.wavesurfer.on('region-mouseleave', (e) => {
      $(`[id=${e.id}]`).css('display', 'none');
    });

    this.setState({ disableRedactButton: false });
  };

  loadRegions = async () => {
    const fetchRegionsRes = await fetchMutedRegions(this.props.jobId);

    if (fetchRegionsRes.status === 200 && fetchRegionsRes.mutedRegions) {
      this.setState({ waveRegions: fetchRegionsRes.mutedRegions });
    }

    this.loadAudio();
  };

  render() {
    return (
      <div className="command-icon" id="toolbar-redact-audio">
        <ButtonImage
          id="toolbar-redact-audio-button"
          name={audioRedaction}
          onClick={() => this.toggle()}
          data_place="right"
          data_tip={this.state.disableRedactButton ? '' : 'Redact Audio'}
          disabled={this.state.disableRedactButton}
        />
        <Popover
          id="popover-audio"
          placement="right"
          isOpen={this.state.popover}
          target="toolbar-redact-audio"
          toggle={this.toggle}
          container="toolbar-redact-audio"
          className="popover-redactaudio"
        >
          <PopoverHeader>
            <strong className="label">Redact Audio Segment</strong>
            <div
              id="close-audio-redaction"
              className="label"
              onClick={() => {
                this.toggle();
              }}
            >
              ×
            </div>
          </PopoverHeader>
          <Popoverbody
            is_video_loaded={this.props.is_video_loaded}
            fps={this.props.fps}
            num_frames={this.props.num_frames}
            metadataLoc={this.props.metadataLoc}
            wavesurfer={this.props.wavesurfer}
            mergeAudio={this.mergeAudio}
          />
        </Popover>
      </div>
    );
  }
}
