import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { ButtonImage } from "./index";
import keyboardIcon1 from "../../Icons/keyboard.1.svg";
import keyboardIcon from "../../Icons/keyboard.png";
import rightArrow from "../../Icons/right-arrow.png";
import leftArrow from "../../Icons/left-arrow.png";

export default class Keyboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState(prevState => ({ show: !prevState.show }));
  }

  render() {
    return (
      <div className="command-icon" id="keyboard-shortcuts">
        <ButtonImage
          id="keyboard-button"
          class={this.props.is_video_loaded ? "" : "disabled"}
          imgid="keyboard-shortcuts-img"
          name={keyboardIcon1}
          data_place="right"
          data_tip="KeyBoard Shortcuts"
          onClick={this.toggleModal}
        />
        <Modal
          isOpen={this.state.show}
          toggle={this.toggleModal}
          id="keyboard-shortcuts-modal"
        >
          <ModalHeader toggle={this.toggleModal} />
          <ModalBody id="keyboard-shortcuts-modal-body">
            <h3 id="modal-name">Keyboard Shortcuts</h3>
            <img src={keyboardIcon} alt="" />
            <br />
            <table id="keyboard-shortcut-table">
              <tbody>
                <tr>
                  <td>1</td>
                  <td style={{ textAlign: "left" }}>play at 0.25x speed</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td style={{ textAlign: "left" }}>play at 0.5x speed</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td style={{ textAlign: "left" }}>play at 1x speed</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td style={{ textAlign: "left" }}>play at 2x speed</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td style={{ textAlign: "left" }}>play at 4x speed</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <br />
                  </td>
                </tr>
                <tr>
                  <td>Space bar</td>
                  <td style={{ textAlign: "left" }}>play / pause</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <br />
                  </td>
                </tr>
                <tr>
                  <td>
                    <img style={{ height: 16 }} src={rightArrow} alt="" />
                  </td>
                  <td style={{ textAlign: "left" }}>move 5 seconds forward</td>
                </tr>
                <tr>
                  <td>
                    <img style={{ height: 16 }} src={leftArrow} alt="" />
                  </td>
                  <td style={{ textAlign: "left" }}>
                    move 5 seconds backwards
                  </td>
                </tr>
                <tr>
                  <td>
                    Shift &nbsp;
                    <img style={{ height: 16 }} src={rightArrow} alt="" />
                  </td>
                  <td style={{ textAlign: "left" }}>move 15 seconds forward</td>
                </tr>
                <tr>
                  <td>
                    Shift &nbsp;
                    <img style={{ height: 16 }} src={leftArrow} alt="" />
                  </td>
                  <td style={{ textAlign: "left" }}>
                    move 15 seconds backwards
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <br />
                  </td>
                </tr>
                <tr>
                  <td>D</td>
                  <td style={{ textAlign: "left" }}>move 1 frame backward</td>
                </tr>
                <tr>
                  <td>F</td>
                  <td style={{ textAlign: "left" }}>move 1 frame forward</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <br />
                  </td>
                </tr>
                <tr>
                  <td>Shift + D</td>
                  <td style={{ textAlign: "left" }}>move 5 frames backward</td>
                </tr>
                <tr>
                  <td>Shift + F</td>
                  <td style={{ textAlign: "left" }}>move 5 frames forward</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <br />
                  </td>
                </tr>
                <tr>
                  <td>G</td>
                  <td style={{ textAlign: "left" }}>
                    play for 0.5 seconds, before pausing
                  </td>
                </tr>
                <tr>
                  <td>H</td>
                  <td style={{ textAlign: "left" }}>
                    play for 1 second, before pausing
                  </td>
                </tr>
                <tr>
                  <td>J</td>
                  <td style={{ textAlign: "left" }}>
                    play for 3 seconds, before pausing
                  </td>
                </tr>
                <tr>
                  <td>K</td>
                  <td style={{ textAlign: "left" }}>
                    play for 5 seconds, before pausing
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <br />
                  </td>
                </tr>
                <tr>
                  <td>V</td>
                  <td style={{ textAlign: "left" }}>
                    rewind 0.5 seconds, then play at quarter speed
                  </td>
                </tr>
                <tr>
                  <td>B</td>
                  <td style={{ textAlign: "left" }}>
                    rewind 1 second, then play at quarter speed
                  </td>
                </tr>
                <tr>
                  <td>N</td>
                  <td style={{ textAlign: "left" }}>
                    rewind 3 seconds, then play at quarter speed
                  </td>
                </tr>
                <tr>
                  <td>M</td>
                  <td style={{ textAlign: "left" }}>
                    rewind 5 seconds, then play at quarter speed
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <br />
                  </td>
                </tr>
                <tr>
                  <td>R</td>
                  <td style={{ textAlign: "left" }}>
                    add manual redaction object
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <br />
                  </td>
                </tr>
                <tr>
                  <td>&lt;Esc&gt;</td>
                  <td style={{ textAlign: "left" }}>
                    cancel creation of new manual redaction object
                  </td>
                </tr>
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-default"
              type="button"
              onClick={this.toggleModal}
            >
              Close
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
