export function Position(xtl, ytl, xbr, ybr, occluded, outside) {
  this.xtl = xtl;
  this.ytl = ytl;
  this.xbr = xbr;
  this.ybr = ybr;
  this.occluded = occluded ? true : false;
  this.outside = outside ? true : false;
  this.width = xbr - xtl;
  this.height = ybr - ytl;

  if (this.xbr <= this.xtl) {
    this.xbr = this.xtl + 1;
  }

  if (this.ybr <= this.ytl) {
    this.ybr = this.ytl + 1;
  }

  this.serialize = function() {
    return (
      '[' +
      Math.round(this.xtl) +
      ',' +
      Math.round(this.ytl) +
      ',' +
      Math.round(this.xbr) +
      ',' +
      Math.round(this.ybr) +
      ',' +
      this.occluded +
      ',' +
      this.outside +
      ']'
    );
  };

  this.move = function(delta) {
    this.xtl = this.xtl + delta.x;
    this.xbr = this.xbr + delta.x;
    this.ytl = this.ytl + delta.y;
    this.ybr = this.ybr + delta.y;

    if (delta.height) {
      this.ybr = this.ybr + delta.height;
      this.height = this.height + delta.height;
    }
    if (delta.width) {
      this.xbr = this.xbr + delta.width;
      this.width = this.width + delta.width;
    }
  };

  this.clone = function() {
    return new Position(
      this.xtl,
      this.ytl,
      this.xbr,
      this.ybr,
      this.occluded,
      this.outside
    );
  };
}
