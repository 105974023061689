import $ from 'jquery';

import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import WaveSurfer from 'wavesurfer.js';
import queryString from 'query-string';

import App from '../app';
import { fetchMetadata, fetchTracks, saveTracks } from '../utils/axiosRequests';
import TrackCollection from '../utils/TrackCollection';
import { Box, Track } from '../utils/trackers';

import InteractionBoxes from './InteractionBoxes';
import styleSettings from '../../../settings.json';

import { host, blurOptions } from '../../../config/globals';
import { errorNotification, warningNotification } from '../ExternalComponents/Notifications';

const React = require('react');
const path = require('path');

const autoSaveInterval = 1000 * 30; // 30sec

document.documentElement.style.setProperty('--bg', styleSettings.color);

class RedactVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_video_loaded: false,
      tracks: [],
      track: undefined,
      trackready: false,
      waveRegions: [],
      uiUpdated: false,
      scaleRatio: 1.0,
      blur: blurOptions.default,
    };

    this.changesMade = false;
    this.metadataLoc = undefined;
    this.videoName = undefined;
    this.fps = undefined;
    this.num_frames = undefined;
    this.restore_metadata = undefined;

    this.track = undefined;
    this.counter = 10000;
    this.manualCounter = 10000;
    this.autoObjectsCounter = 0;
    this.availcolors = ['#7aa644', '#447aa6', '#a6447a', '#ffca49', '#922727', '#b66c4b'];

    this.monitor_video_loading_progress = this.monitor_video_loading_progress.bind(this);
    this.load_video = this.load_video.bind(this);
    this.addTrack = this.addTrack.bind(this);
    this.setTrackReady = this.setTrackReady.bind(this);
  }
  componentDidMount() {
    const color = this.getColor();

    $(document).ready(function () {
      var body = document.getElementsByTagName('BODY')[0];
      body.style.display = 'block';
    });

    var pathToColor = '/colors/';
    var fullPath = pathToColor + color + '.css';

    this.addCSS(fullPath);
    let width = 5;
    let video_load_progress_bar = document.getElementById('video-load-progress-bar');
    video_load_progress_bar.style.width = width + '%';

    // Getting the jobID,metadata folder and video from URL
    this.jobId = queryString.parse(this.props.location.search).jobId;
    this.metadataLoc = decodeURIComponent(queryString.parse(this.props.location.search).metadataFolder);
    this.redactvideo_src = decodeURIComponent(queryString.parse(this.props.location.search).video);

    if (!this.jobId || !this.metadataLoc || !this.redactvideo_src) {
      errorNotification('Please check the url provided');
      return;
    }

    this.metadataLoc = path.join(this.metadataLoc, this.jobId);

    this.monitor_video_loading_progress(video_load_progress_bar);

    this.saveTracksToLocalInterval = setInterval(() => {
      if (this.changesMade) {
        this.saveTracksToLocal();
      }
    }, autoSaveInterval);

    this.wavesurfer = WaveSurfer.create({
      container: '#waveform',
      progressColor: 'rgb(100, 149, 237)',
      cursorColor: 'black',
      plugins: [
        RegionsPlugin.create({
          regions: [],
        }),
        TimelinePlugin.create({
          container: '#wave-timeline',
          primaryColor: 'black',
          primaryFontColor: 'black',
          secondaryColor: 'black',
          secondaryFontColor: 'black',
        }),
      ],
    });
  }

  componentWillUnmount() {
    try {
      if (this.changesMade) {
        this.saveTracksToLocal();
      }

      TrackCollection.clear();

      if (this.saveTracksToLocalInterval) {
        clearInterval(this.saveTracksToLocalInterval);
      }

      if (this.wavesurfer) {
        this.wavesurfer.empty();
        this.wavesurfer.unAll();
      }
    } catch (err) {}
  }

  setBlur = (blur) => {
    this.setState({ blur });
  };

  setScaleRatio = (ratio) => {
    this.setState({ scaleRatio: ratio });
  };

  updateUI = () => {
    this.changesMade = true;
    this.setState({ uiUpdated: !this.state.uiUpdated });
  };

  getColor = () => {
    const availableColors = [
      'red',
      'green',
      'blue',
      'purple',
      'yellow',
      'skyBlue',
      'violet',
      'orange',
      'lightgreen',
      'royalblue',
    ];
    let color = queryString.parse(this.props.location.search).bgColor;
    if (availableColors.indexOf(color) === -1) {
      color = 'royalblue';
    }
    return color;
  };

  addCSS = (filename) => {
    var head = document.getElementsByTagName('head')[0];
    var style = document.createElement('link');

    style.href = filename;
    style.type = 'text/css';
    style.rel = 'stylesheet';
    head.append(style);
  };

  disableUI = (disableVolumeBar = false) => {
    $('#settings-button').prop('disabled', true).css({ opacity: 0.3, pointerEvents: 'none' });
    $('#keyboard-button').prop('disabled', true).css({ opacity: 0.3, pointerEvents: 'none' });
    $('#new-redaction-box-button').prop('disabled', true).css({ opacity: 0.3, pointerEvents: 'none' });
    $('#toolbar-mergepath-button').prop('disabled', true).css({ opacity: 0.3, pointerEvents: 'none' });
    $('#new-auto-face-redaction-button').prop('disabled', true).css({ opacity: 0.3, pointerEvents: 'none' });
    $('#new-auto-body-redaction-button').prop('disabled', true).css({ opacity: 0.3, pointerEvents: 'none' });

    if (disableVolumeBar) {
      $('#volume-bar').prop('disabled', true);
      $('#volume-button').prop('disabled', true);
    }
  };

  enableUI = () => {
    $('#settings-button').prop('disabled', false).css({ opacity: 1, pointerEvents: 'auto' });
    $('#keyboard-button').prop('disabled', false).css({ opacity: 1, pointerEvents: 'auto' });
    $('#new-redaction-box-button').prop('disabled', false).css({ opacity: 1, pointerEvents: 'auto' });
    $('#toolbar-mergepath-button').prop('disabled', false).css({ opacity: 1, pointerEvents: 'auto' });
    $('#new-auto-face-redaction-button').prop('disabled', true).css({ opacity: 0.3, pointerEvents: 'none' });
    $('#new-auto-body-redaction-button').prop('disabled', true).css({ opacity: 0.3, pointerEvents: 'none' });
  };

  monitor_video_loading_progress = async (ele) => {
    this.disableUI(true);

    // Fetch the video metadata before loading
    const fetchRes = await fetchMetadata(this.jobId);

    if (fetchRes.status !== 200) {
      errorNotification('Failed to load the video');
      return;
    }

    const metadata = fetchRes.metadata;

    this.fps = metadata.fps;
    this.num_frames = metadata.framescount;
    this.videoMetadata = fetchRes.metadata;

    // Assign the numFrames in TrackCollection
    TrackCollection.numFrames = metadata.framescount;

    // Load the video
    this.load_video();

    // Enable all the buttons
    $('#play-pause-button').prop('disabled', false);
    $('#volume-button').prop('disabled', false);
    $('#volume-bar').prop('disabled', false);
  };

  injectTrack = (labelid, obj, type) => {
    if (!obj) {
      return;
    }

    let newTrack;
    this.autoObjectsCounter = this.autoObjectsCounter + 1;

    const name = type === 'face' ? `Face ${this.autoObjectsCounter}` : `Person ${this.autoObjectsCounter}`;

    const color = this.availcolors[Math.floor(Math.random() * this.availcolors.length)];

    obj.map((dataList) => {
      const newBox = new Box(
        parseInt(dataList[0] * this.state.scaleRatio, 10), // x1
        parseInt(dataList[1] * this.state.scaleRatio, 10), // y1
        parseInt(dataList[2] * this.state.scaleRatio, 10), // x2
        parseInt(dataList[3] * this.state.scaleRatio, 10), // y2
        dataList[5], // Visibility
        dataList[6], // isManual
      );

      if (!newTrack) {
        newTrack = new Track(
          this.autoObjectsCounter,
          name,
          'auto',
          color,
          newBox,
          dataList[4], // frameID
        );
        newTrack.objectType = 'face';
        newTrack.labelid = labelid;
        newTrack.firstVisibleFrameID = dataList[4];
        newTrack.lastNonVisibleFrameID = null;
        TrackCollection.addNew(newTrack);
      } else {
        newTrack.insert(dataList[4], newBox);
      }

      return 1; // To avoid warnings
    });
  };

  injectManualTrack = (labelid, obj, id) => {
    if (!obj) {
      return;
    }

    this.manualCounter = Math.max(this.manualCounter, parseInt(id, 10));
    const color = this.availcolors[Math.floor(Math.random() * this.availcolors.length)];

    let newTrack;
    obj.map((dataList) => {
      const newBox = new Box(
        parseInt(dataList[0] * this.state.scaleRatio, 10), // x1
        parseInt(dataList[1] * this.state.scaleRatio, 10), // y1
        parseInt(dataList[2] * this.state.scaleRatio, 10), // x2
        parseInt(dataList[3] * this.state.scaleRatio, 10), // y2
        dataList[5],
        dataList[6], // isManual - should be true for manual tracks
      );

      if (!newTrack) {
        newTrack = new Track(
          parseInt(id, 10),
          `${dataList[9]}`, // label
          'manual',
          color,
          newBox,
          dataList[4],
        );
        newTrack.objectType = 'face'; // objectType could be anything for manual track(defaults to face)
        newTrack.labelid = labelid;
        newTrack.firstVisibleFrameID = dataList[7];
        newTrack.lastNonVisibleFrameID = dataList[8];
        TrackCollection.addNew(newTrack);
      } else {
        newTrack.insert(dataList[4], newBox);
      }

      return 1; // To avoid warnings
    });
  };

  continueRedaction = async (showManual, objectType) => {
    this.manualCounter = this.counter;

    // Adding Loading data element to the DOM
    $(
      "<div id='loading-saved-data' style='padding: 10px 0px 15px 10px'><div style='padding: 0px 0px 15px 0px; font-size: 16px; color: #9f9f9f;'>Loading data...<br/></div></div>",
    ).prependTo($('#path-selector-box-objects'));

    // Get the tracks from the backend
    const fetchTracksRes = await fetchTracks(this.jobId, objectType);
    if (fetchTracksRes.error) {
      errorNotification('Auto Face Detection Failed.Try scanning the video again');
      $('#loading-saved-data').remove();
      return;
    }

    if (!fetchTracksRes.tracks) {
      $('#loading-saved-data').remove();
      return;
    }

    let annotations = {};
    let autoObjectsLength = 0;

    annotations = fetchTracksRes.tracks;
    autoObjectsLength = Object.keys(annotations.objects_info).length;

    TrackCollection.tracks = [];
    this.counter = 10000;
    this.manualCounter = 10000;
    this.autoObjectsCounter = 0;

    if (autoObjectsLength === 0 && !showManual) {
      warningNotification(`No ${objectType}s found`);
    } else {
      const trackIDs = Object.keys(annotations.objects_info);
      for (const trackID of trackIDs) {
        const boxesList = annotations.objects_info[trackID];
        if (boxesList.length <= 0) {
          continue;
        }

        // Injecting automated tracks to the DOM
        this.injectTrack(0, boxesList, objectType);
      }
    }

    // Get the total objects length
    const totalObjectsLength =
      autoObjectsLength +
      Object.keys(annotations.manual_redaction['0']).length +
      Object.keys(annotations.manual_redaction['1']).length +
      Object.keys(annotations.manual_redaction['2']).length;

    // If there is no objects, remove Loading data... element
    if (totalObjectsLength === 0) {
      $('#loading-saved-data').remove();
    }

    const trackCategories = Object.keys(annotations.manual_redaction);
    for (const trackCategory of trackCategories) {
      const boxesCollection = annotations.manual_redaction[trackCategory]; // Person || Vehicle || Custom
      const trackIDs = Object.keys(boxesCollection);

      for (const trackID of trackIDs) {
        if (boxesCollection[trackID].length <= 0) {
          continue;
        }

        // Injecting manual tracks to the DOM
        this.injectManualTrack(trackCategory, boxesCollection[trackID], trackID);
      }
    }

    // To update the react UI
    this.updateUI();

    //To revert back to false since this.updateUI() changes to true...No changes made since it is fetching only the existing data
    if (showManual) {
      this.changesMade = false;
    }

    // @TODO: Figure a good way to save and retrieve data
    // Saving data to facemetadata.json after completing person detection
    if (objectType === 'person') {
      this.saveTracksToLocal();
    }

    this.counter = this.manualCounter;
  };

  loadVideoAt = () => {
    const videoStartTime = queryString.parse(this.props.location.search).videoStartTime;
    if (videoStartTime) {
      const video = document.getElementById('video');
      video.currentTime = videoStartTime;
    }
  };

  load_video() {
    var videourl = new URL(host() + `/suspect/static${this.redactvideo_src}`);
    document.getElementById('video').src = videourl;
    document.getElementById('video').load();
    this.loadVideoAt();
    this.enableUI();
    let loading_video_dom_node = document.getElementById('loadingVideo');
    let loading_video_progress = document.getElementById('video-load-progress');

    if (!!loading_video_dom_node) {
      let parent_node = loading_video_dom_node.parentNode;
      parent_node.removeChild(loading_video_dom_node);
      parent_node.removeChild(loading_video_progress);
    }

    document.getElementById('video').addEventListener('loadedmetadata', () => {
      this.continueRedaction(true, 'face');
      this.setState(() => ({ is_video_loaded: true }));
    });
  }

  addTrack(position, color) {
    const frame = Math.floor(document.getElementById('video').currentTime * this.fps);
    this.counter = this.counter + 1;
    this.track = {
      id: this.counter,
      color,
      position: {},
      frame: [frame],
      ready: false,
      visible: true,
      selected: false,
      automated: false,
      firstVisibleFrameID: frame,
      last_visible: this.num_frames,
    };
    this.track.position[frame] = position;

    // Scrolling selector box to top
    $('#path-selector-box').scrollTop(0);

    this.setState({ track: this.track });
  }

  setTrackReady(value) {
    this.setState(() => ({ trackready: value }));
    if (value === true && this.track && this.track.ready === true) {
      const box = new Box(
        this.track.position[this.track.firstVisibleFrameID].xtl,
        this.track.position[this.track.firstVisibleFrameID].ytl,
        this.track.position[this.track.firstVisibleFrameID].xbr,
        this.track.position[this.track.firstVisibleFrameID].ybr,
        true, // visible
        true, // isManual
      );

      TrackCollection.tracks.map((track) => {
        if (track.selected === true) {
          const handle = document.getElementById('redaction-object' + track.id);
          if (handle && handle.classList) {
            handle.classList.remove('trackobject-selected');
          }
        }

        return 1; // To avoid warnings
      });

      const newTrack = new Track(
        this.track.id,
        this.track.label,
        'manual',
        this.track.color,
        box,
        this.track.firstVisibleFrameID,
      );

      newTrack.objectType = 'face'; // objectType could be anything for manual track(defaults to face)
      newTrack.labelid = this.track.labelid;

      TrackCollection.addNew(newTrack);
      TrackCollection.selectTrack(this.track.id);
    }

    // Just for making UI changes
    this.updateUI();
  }

  saveTracksToLocal = async () => {
    const saveTracksRes = await saveTracks(this.jobId, TrackCollection.tracks, 1 / this.state.scaleRatio);

    if (saveTracksRes.status === 200) {
      this.changesMade = false;
    }
  };

  render() {
    return (
      <React.Fragment>
        <canvas id="canvas2" />
        <div id="canvas">
          <div id="loadingVideo">Loading video</div>
          <div
            id="video-load-progress"
            className="progressbar ui-progressbar ui-widget ui-corner-all ui-widget-content"
          >
            <div id="video-load-progress-bar" className="ui-progressbar-value ui-widget-header ui-corner-left" />
          </div>
          <div id="slider-preview" />
          <div id="slider-preview-ts">0:00:00.00</div>
          <InteractionBoxes
            fps={this.fps}
            blur={this.state.blur}
            updateUI={this.updateUI}
            tracks={this.state.tracks}
            num_frames={this.num_frames}
            uiUpdated={this.state.uiUpdated}
            video={document.getElementById('video')}
          />
        </div>
        <App
          is_video_loaded={this.state.is_video_loaded}
          fps={this.fps}
          num_frames={this.num_frames}
          restore_metadata={this.restore_metadata}
          metadata={this.metadataLoc}
          trackReady={this.state.trackready}
          addTrack={this.addTrack}
          injectTrack={this.injectTrack}
          delTrack={this.delTrack}
          setTrackReady={this.setTrackReady}
          counter={this.counter}
          tracks={this.state.tracks}
          track={this.state.track}
          wavesurfer={this.wavesurfer}
          redactvideo_src={this.redactvideo_src}
          enableUI={this.enableUI}
          disableUI={this.disableUI}
          continueRedaction={this.continueRedaction}
          mergeAudio={this.mergeAudio}
          deleteAllTracks={this.deleteAllTracks}
          jobId={this.jobId}
          updateUI={this.updateUI}
          uiUpdated={this.state.uiUpdated}
          videoMetadata={this.videoMetadata}
          scaleRatio={this.state.scaleRatio}
          setScaleRatio={this.setScaleRatio}
          blur={this.state.blur}
          setBlur={this.setBlur}
        />
      </React.Fragment>
    );
  }
}

export default RedactVideo;
