import React, { Component } from "react";

export default class ButtonImage extends Component {
  render() {
    return (
      <button
        className="img-btn"
        type="button"
        onClick={this.props.onClick}
        id={this.props.id}
        disabled={this.props.disabled}
        style={this.props.buttonStyle}
      >
        <img
          id={this.props.imgid}
          style={this.props.style}
          alt="Error"
          src={this.props.name}
          data-tip={this.props.data_tip}
          data-html={this.props.data_html}
          data-place={this.props.data_place}
          className={this.props.disabled ? "disabled" : this.props.class}
        />
      </button>
    );
  }
}

ButtonImage.defaultProps = {
  style: {
    height: 32
  }
};
