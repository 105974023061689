import React, { Component } from "react";
import $ from "jquery";

import { ButtonImage } from "./index";
import playIcon from "../../Icons/play.svg";

export default class Play extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.video = document.getElementById("video");
    document.addEventListener("keypress", this.handlekeypress);
  }

  componentWillUnmount() {
    document.removeEventListener("keypress", this.handlekeypress);
  }

  handlekeypress = e => {
    e.preventDefault();
    $(document.activeElement).blur();
    const c = String.fromCharCode(e.which);
    if (c === " ") {
      this.toggle();
    }
  };

  toggle = () => {
    if (this.props.is_video_loaded)
      if (!this.video.paused) {
        this.props.pause();
      } else {
        this.props.play();
      }
  };

  render() {
    return (
      <ButtonImage
        id="play-pause-button"
        imgid="play-pause-img"
        name={playIcon}
        onClick={() => this.toggle()}
        style={style}
        class={this.props.is_video_loaded ? "" : "disabled"}
      />
    );
  }
}

const style = {
  height: 32,
  verticalAlign: "baseline",
  cursor: "pointer"
};
