import React from 'react';
import { Modal, ModalHeader, ModalBody, Table } from 'reactstrap';

import { ButtonImage } from './index';
import { blurOptions, qualityOptions } from '../../config/globals';
import { fetchSettings, saveSettings } from './utils/axiosRequests';

import mask350 from '../../Icons/mask_350.jpg';
import solid350 from '../../Icons/solid_350.jpg';
import smooth350 from '../../Icons/smooth_350.jpg';
import settingsIcon from '../../Icons/settings.svg';
import pixelate350 from '../../Icons/pixelate_350.jpg';

export default class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalStatus: false,
      quality: qualityOptions.default,
    };
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.jobId === this.props.jobId) {
      return;
    }

    const res = await fetchSettings(this.props.jobId);

    if (res.settings) {
      this.props.setBlur(res.settings.blur);
      this.setState({ quality: res.settings.quality });
    }
  }

  toggle = () => {
    this.setState(() => ({
      modalStatus: !this.state.modalStatus,
    }));
  };

  toggleBlurStyle = async (blur) => {
    this.props.setBlur(blur);
    await saveSettings({ blur, quality: this.state.quality }, this.props.jobId);
  };

  changeQuality = async (e) => {
    this.setState({ quality: Number(e.target.value) });
    await saveSettings({ blur: this.props.blur, quality: Number(e.target.value) }, this.props.jobId);
  };

  render() {
    const { props } = this;
    return (
      <div className="command-icon" id="settings">
        <ButtonImage
          id="settings-button"
          class={this.props.is_video_loaded ? '' : 'disabled'}
          imgid="settings-img"
          name={settingsIcon}
          data_place="right"
          data_tip="Settings"
          onClick={this.toggle}
        />
        <Modal isOpen={this.state.modalStatus} toggle={this.toggle} id="settings-modal">
          <ModalHeader toggle={this.toggle} />
          <ModalBody id="settings-modal-body">
            <span style={{ fontSize: 24, color: '#9f9f9f' }}>Settings</span>
            <br />
            <br />
            <br />

            <div className="panel">
              <div className="panel-heading">
                Redaction Style
                <br />
                <span>(will only be present in final generated video)</span>
              </div>
              <div className="panel-body">
                <Table>
                  <tbody>
                    <tr>
                      <td>
                        <img
                          alt="Error"
                          width={150}
                          src={pixelate350}
                          onClick={() => this.toggleBlurStyle(blurOptions.pixelate)}
                          className={`redaction-box-blur ${props.blur === blurOptions.pixelate ? 'selected' : ''}`}
                        />
                        <br />
                        <strong style={{ fontSize: '110%', color: 'white' }}>Pixelate</strong>
                      </td>
                      <td>
                        <img
                          alt="Error"
                          width="150"
                          src={mask350}
                          onClick={() => this.toggleBlurStyle(blurOptions.mask)}
                          className={`redaction-box-blur ${props.blur === blurOptions.mask ? 'selected' : ''}`}
                        />
                        <br />
                        <strong style={{ fontSize: '110%', color: 'white' }}>Mask</strong>
                      </td>
                      <td>
                        <img
                          alt="Error"
                          width="150"
                          src={smooth350}
                          onClick={() => this.toggleBlurStyle(blurOptions.smooth)}
                          className={`redaction-box-blur ${props.blur === blurOptions.smooth ? 'selected' : ''}`}
                        />
                        <br />
                        <strong style={{ fontSize: '110%', color: 'white' }}>Smooth</strong>
                      </td>
                      <td>
                        <img
                          alt="Error"
                          width="150"
                          src={solid350}
                          onClick={() => this.toggleBlurStyle(blurOptions.solid)}
                          className={`redaction-box-blur ${props.blur === blurOptions.solid ? 'selected' : ''}`}
                        />
                        <br />
                        <strong style={{ fontSize: '110%', color: 'white' }}>Solid</strong>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="panel">
                <div className="panel-heading">
                  Output Video Quality
                  <br />
                </div>
                <div className="panel-body">
                  <input
                    type="radio"
                    name="quality"
                    id="lowQualityRadio"
                    value={qualityOptions.low}
                    onChange={this.changeQuality}
                    className="video-quality-input"
                    checked={this.state.quality === qualityOptions.low}
                  />
                  Low
                  <input
                    type="radio"
                    name="quality"
                    id="medQualityRadio"
                    value={qualityOptions.medium}
                    onChange={this.changeQuality}
                    className="video-quality-input"
                    checked={this.state.quality === qualityOptions.medium}
                  />
                  Medium
                  <input
                    type="radio"
                    name="quality"
                    id="highQualityRadio"
                    value={qualityOptions.high}
                    onChange={this.changeQuality}
                    className="video-quality-input"
                    checked={this.state.quality === qualityOptions.high}
                  />
                  High
                </div>
              </div>
            </div>

            <br />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
