import React, { Component } from "react";

import { Play, PlaybackRate, SliderBar, VolumeBar, VolumeButton } from "./index";

import Duration from "./HelperComponents/Duration";
export default class BottomBar extends Component {
  render() {
    return (
      <div id="video-controls">
        <Play
          is_video_loaded={this.props.is_video_loaded}
          pause={this.props.pause}
          play={this.props.play}
          wavesurfer={this.props.wavesurfer}
        />
        <PlaybackRate pause={this.props.pause} wavesurfer={this.props.wavesurfer} play={this.props.play} />
        <SliderBar
          is_video_loaded={this.props.is_video_loaded}
          fps={this.props.fps}
          num_frames={this.props.num_frames}
          metadata={this.props.metadata}
          wavesurfer={this.props.wavesurfer}
          jobId={this.props.jobId}
        />
        <Duration fps={this.props.fps} num_frames={this.props.num_frames} wavesurfer={this.props.wavesurfer} />
        <VolumeButton wavesurfer={this.props.wavesurfer} />
        <VolumeBar wavesurfer={this.props.wavesurfer} />
      </div>
    );
  }
}
