import React from "react";
import $ from "jquery";

import { VideoFrame, PathSelectorBox, Sidebar, BottomBar, Waves, AudioComponent } from "./index";
import spacer from "../../Icons/spacer.gif";
import playIcon from "../../Icons/play.svg";
import pauseIcon from "../../Icons/pause.svg";
import { host } from "../../config/globals";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSelectionBox: false,
    };

    this.fps = this.props.fps;

    this.video = undefined;
    this.frame = undefined;

    this.interval = undefined;

    this.pause = this.pause.bind(this);
    this.play = this.play.bind(this);
    this.toggleSelectionBox = this.toggleSelectionBox.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.keyDown = this.keyDown.bind(this);
    this.setFrame = this.setFrame.bind(this);
  }

  componentDidMount() {
    this.video = document.getElementById("video");
    this.video.addEventListener("timeupdate", () => {
      // Avoiding video to reach 0th frame
      if (this.fps && this.video.currentTime < 1 / this.fps) {
        this.video.currentTime = 1 / this.fps + 0.00001; // To make sure it reaches frame 1
      }

      this.frame = Math.floor(this.video.currentTime * this.fps);

      if (this.video.currentTime >= this.video.duration) {
        this.pause();
      }
    });

    document.addEventListener("keypress", this.keyPress);
    document.addEventListener("keydown", this.keyDown);
  }

  componentDidUpdate() {
    this.fps = this.props.fps;
  }

  componentWillUnmount() {
    document.removeEventListener("keypress", this.keyPress);
    document.removeEventListener("keydown", this.keyDown);
  }

  play() {
    this.video.play();
    this.setFrame(0);
    document.getElementById("play-pause-img").src = pauseIcon;
  }

  pause() {
    this.video.pause();
    this.frame = Math.floor(this.video.currentTime * this.fps);
    this.setFrame(this.frame);
    document.getElementById("play-pause-img").src = playIcon;
  }

  setFrame(frame) {
    if (frame <= 0 || isNaN(frame)) {
      $("#video_jpg_overlay").attr("src", spacer);
    } else {
      if (frame > this.props.num_frames) {
        frame = Math.max(0, this.props.num_frames - 1);
      }

      const url = new URL(`${host()}/suspect/static${this.props.metadata}/imgs/${frame}.jpg`);
      $("#video_jpg_overlay").attr("src", url);
    }
  }

  toggleSelectionBox(value) {
    this.setState({ showSelectionBox: value });
  }

  // console.log('rendering video player',this.props.fps,this.props.num_frames)

  keyPress(e) {
    e.preventDefault();
    let shift, pauseTime, intervalId;
    if (!this.props.is_video_loaded) {
      return;
    }

    switch (e.keyCode) {
      case 68: //d
      case 100:
        if (e.shiftKey) {
          shift = 5;
        } else {
          shift = 1;
        }
        this.frame -= shift;

        this.video.currentTime -= shift / this.fps + 0.00001;

        if (this.props.wavesurfer && this.props.wavesurfer.getDuration() > 0) {
          this.props.wavesurfer.skipBackward(shift / this.fps + 0.00001);
        }

        this.pause();
        break;
      case 70: //f
      case 102:
        if (e.shiftKey) {
          shift = 5;
        } else {
          shift = 1;
        }
        this.frame += shift;

        this.video.currentTime += shift / this.fps + 0.00001;

        if (this.props.wavesurfer && this.props.wavesurfer.getDuration() > 0) {
          this.props.wavesurfer.skipForward(shift / this.fps + 0.00001);
        }

        this.pause();
        break;
      case 103: //g
      case 71:
        pauseTime = this.video.currentTime + 0.5 + 0.00001;
        intervalId = setInterval(() => {
          if (this.video.paused) {
            clearInterval(intervalId);
          } else {
            if (this.video.currentTime >= pauseTime) this.pause();
          }
        }, 25);
        this.play();
        break;
      case 104: //h
      case 72:
        pauseTime = this.video.currentTime + 1.0 + 0.00001;
        intervalId = setInterval(() => {
          if (this.video.paused) {
            clearInterval(intervalId);
          } else {
            if (this.video.currentTime >= pauseTime) this.pause();
          }
        }, 25);
        this.play();
        break;
      case 106: //j
      case 74:
        pauseTime = this.video.currentTime + 3.0 + 0.00001;
        intervalId = setInterval(() => {
          if (this.video.paused) {
            clearInterval(intervalId);
          } else {
            if (this.video.currentTime >= pauseTime) this.pause();
          }
        }, 25);
        this.play();
        break;
      case 107: //k
      case 75:
        pauseTime = this.video.currentTime + 5.0 + 0.00001;
        intervalId = setInterval(() => {
          if (this.video.paused) {
            clearInterval(intervalId);
          } else {
            if (this.video.currentTime >= pauseTime) this.pause();
          }
        }, 25);
        this.play();
        break;
      default:
        return 0;
    }
  }

  keyDown(e) {
    e.stopPropagation();
    e.cancelBubble = true;
    let secs;
    if (!this.props.is_video_loaded) {
      return;
    }

    switch (e.keyCode) {
      case 37: // left arrow
        e.preventDefault();
        secs = e.shiftKey ? 15 : 5;
        this.video.currentTime -= secs + 0.00001;
        if (this.props.wavesurfer && this.props.wavesurfer.getDuration() > 0) {
          this.props.wavesurfer.skip(-5);
        }

        this.pause();

        break;
      case 39: // right arrow
        e.preventDefault();
        secs = e.shiftKey ? 15 : 5;
        this.video.currentTime += secs + 0.00001;
        if (this.props.wavesurfer && this.props.wavesurfer.getDuration() > 0) {
          this.props.wavesurfer.skip(5);
        }

        this.pause();

        break;
      default:
        return 0;
    }
  }

  render() {
    return (
      <div style={{ display: "inline" }}>
        <VideoFrame
          fps={this.props.fps}
          videoMetadata={this.props.videoMetadata}
          setScaleRatio={this.props.setScaleRatio}
          wavesurfer={this.props.wavesurfer}
        />
        <PathSelectorBox
          show={this.state.showSelectionBox}
          track={this.props.track}
          tracks={this.props.tracks}
          fps={this.props.fps}
          num_frames={this.props.num_frames}
          hideBox={this.toggleSelectionBox}
          setobjready={this.props.setTrackReady}
          pause={this.pause}
          deleteTrack={this.props.delTrack}
          setFrame={this.setFrame}
          wavesurfer={this.props.wavesurfer}
          updateUI={this.props.updateUI}
          uiUpdated={this.props.uiUpdated}
        />
        <Sidebar
          is_video_loaded={this.props.is_video_loaded}
          showBox={this.toggleSelectionBox}
          objready={this.props.trackReady}
          tracks={this.props.tracks}
          fps={this.props.fps}
          num_frames={this.props.num_frames}
          restore_metadata={this.props.restore_metadata}
          metadataLoc={this.props.metadata}
          setobjready={this.props.setTrackReady}
          addTrack={this.props.addTrack}
          injectTrack={this.props.injectTrack}
          deleteTrack={this.props.delTrack}
          play={this.play}
          pause={this.pause}
          wavesurfer={this.props.wavesurfer}
          counter={this.props.counter}
          redactvideo_src={this.props.redactvideo_src}
          enableUI={this.props.enableUI}
          disableUI={this.props.disableUI}
          continueRedaction={this.props.continueRedaction}
          mergeAudio={this.props.mergeAudio}
          updateUI={this.props.updateUI}
          uiUpdated={this.props.uiUpdated}
          deleteAllTracks={this.props.deleteAllTracks}
          jobId={this.props.jobId}
          videoMetadata={this.props.videoMetadata}
          blur={this.props.blur}
          setBlur={this.props.setBlur}
          scaleRatio={this.props.scaleRatio}
        />
        <BottomBar
          is_video_loaded={this.props.is_video_loaded}
          fps={this.props.fps}
          num_frames={this.props.num_frames}
          metadata={this.props.metadata}
          play={this.play}
          pause={this.pause}
          wavesurfer={this.props.wavesurfer}
          jobId={this.props.jobId}
        />
        <table style={{ marginBottom: "40px" }}>
          <tbody>
            <tr>
              <td>
                <AudioComponent wavesurfer={this.props.wavesurfer} />
              </td>

              <td>
                <Waves
                  fps={this.props.fps}
                  num_frames={this.props.num_frames}
                  wavesurfer={this.props.wavesurfer}
                  metadata={this.props.metadata}
                  jobId={this.props.jobId}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
