import React, { Component } from "react";
import $ from "jquery";
import "jquery-ui/themes/base/progressbar.css";
import "jquery-ui/ui/widgets/progressbar";
import { Popover, PopoverHeader, PopoverBody, Form, FormGroup, Label, Input } from "reactstrap";

import newBodyAutoRedaction from "../../../Icons/new_body_auto_redaction.svg";
import { ButtonImage } from "../index";
import { warningNotification, errorNotification } from "../ExternalComponents/Notifications";
import { doRedaction, fetchRedactProgress } from "../utils/axiosRequests";

const { formattedTimeSMPTE } = require("../HelperComponents/toSMPTE");

const toFrames = (SMPTE, fps) => {
  let time = SMPTE.split(":"); //(!SMPTE) ? this.toSMPTE().split(':') : SMPTE.split(':');
  let hh = Number(time[0]) * 60 * 60 * fps;
  let mm = Number(time[1]) * 60 * fps;
  let ss = Number(time[2]) * fps;
  let ff = (Number(time[3]) / 100) * fps;
  return Math.floor(hh + mm + ss + ff);
};

export default class NewBodyAutoRedactionBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popover: false,
      redactedPeriods: [],
    };
    this.already_requested_body_redaction = false;
    this.already_processed_redaction_type = false;

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    if (this.props.is_video_loaded) {
      this.setState(() => ({
        popover: !this.state.popover,
      }));
    }
  }

  start_body_auto_redaction = (period) => {
    this.toggle();
    this.props.disableUI();
    if (this.props.wavesurfer && this.props.wavesurfer.getDuration() > 0) {
      this.props.wavesurfer.seekTo(0);
    }

    console.log("starting body auto redaction", period);
    period = period || "whole";

    if (document.getElementById("toolbar-new-body-auto-redaction-img").className === "disabled") {
      return;
    }

    if (period === "whole" && this.already_requested_body_redaction) {
      let ele = document.getElementById("toolbar-new-body-auto-redaction-img");
      let arr = ele.className.split(" ");
      if (arr.indexOf("disabled") === -1) {
        ele.className += " disabled";
      }
      this.already_requested_body_redaction = true;
    }

    if (period !== "whole") {
      var body_auto_redaction_status_div = $(
        "<div id='body-auto-redaction-status-div" +
          "' style='padding: 10px 0px 15px 10px'><div style='padding: 0px 0px 15px 0px; font-size: 16px; font-weight: bold; color: #9f9f9f;'>Automated Body Detection<br/><span id='detect_or_asm_label' style='font-size: 12px;'>Searching for bodies...</span></div><div id='body-progress-bar" +
          "' style='height: 10px;' class='progressbar'></div><div id='cancel-body-automated-redaction" +
          "' style='cursor: pointer; padding-top: 10px; color: #ed1c24; font-size: 11px; text-decoration: underline; text-align: center;'>Cancel Automated Redaction</div></div>",
      ).prependTo($("#path-selector-box-objects"));
      body_auto_redaction_status_div.hide().slideDown(1500);

      $("#cancel-body-automated-redaction").click(() => {
        clearInterval(this.interval);

        this.props.enableUI();

        body_auto_redaction_status_div.slideUp(1000, function () {
          $(this).remove();
          $("#toolbar-new-body-auto-redaction-img").removeClass("disabled");
          this.already_requested_body_redaction = false;
        });
      });

      var body_redaction_progress_bar = $("#body-progress-bar");
      body_redaction_progress_bar.progressbar({ value: 0 });
      body_redaction_progress_bar
        .find(".ui-progressbar-value")
        .css({ background: "rgb(246, 135, 47)", height: "10px" });

      this.monitor_redaction_progress(period);
    }
  };

  monitor_redaction_progress = async (period) => {
    const redactRes = await doRedaction(this.props.jobId, "person");
    const cancelRedaction = document.getElementById("cancel-body-automated-redaction");

    if (redactRes.status !== 200) {
      cancelRedaction.click();
      errorNotification("Autoperson redaction failed.Try redacting once again.");
      return;
    }

    this.interval = setInterval(async () => {
      try {
        const progressRes = await fetchRedactProgress(this.props.jobId, "person");
        if (progressRes.status !== 200) {
          console.log("Error while reading progress.json");
          errorNotification("Autoperson redaction failed.Try redacting once again.");
          clearInterval(this.interval);
          cancelRedaction.click();
          return;
        }

        $("#body-progress-bar").progressbar("option", "value", progressRes.progress);

        if (progressRes.progress === -1) {
          document.getElementById("detect_or_asm_label").innerHTML = "Process in queue...";
          return;
        }

        if (progressRes.progress > 75) {
          document.getElementById("detect_or_asm_label").innerHTML = "Merging redaction paths...";
        }

        document.getElementById("detect_or_asm_label").innerHTML = "Detecting Persons...";

        if (progressRes.progress === 100) {
          clearInterval(this.interval);

          const body_auto_redaction_status_div = $("#body-auto-redaction-status-div");
          body_auto_redaction_status_div.remove();
          var body_redaction_progress_bar = $("#body-progress-bar");
          body_redaction_progress_bar.progressbar({ value: 0 });
          this.props.enableUI();
          this.props.continueRedaction(false, "person");
        }
      } catch (err) {
        clearInterval(this.interval);
        cancelRedaction.click();
        console.log(err);
      }
    }, 10000);
  };

  render() {
    return (
      <div className="command-icon" id="toolbar-new-body-auto-redaction">
        <ButtonImage
          id="new-auto-body-redaction-button"
          name={newBodyAutoRedaction}
          imgid="toolbar-new-body-auto-redaction-img"
          class={this.props.is_video_loaded ? "" : "disabled"}
          onClick={this.toggle}
          style={{ marginTop: 8, height: 32 }}
          data_place="right"
          data_html="true"
          disabled
        />
        <Popover
          id="popover-body"
          placement="right"
          isOpen={this.state.popover}
          target={"toolbar-new-body-auto-redaction"}
          toggle={this.toggle}
          container={"toolbar-new-body-auto-redaction"}
          className="popover-detectbodies"
        >
          <PopoverHeader>
            <strong className="label">Detect Bodies</strong>
            <div id="close-body-auto-redaction" className="label" onClick={this.toggle}>
              ×
            </div>
          </PopoverHeader>
          <Popoverbody
            start_body_auto_redaction={this.start_body_auto_redaction}
            redactedPeriods={this.state.redactedPeriods}
            fps={this.props.fps}
            num_frames={this.props.num_frames}
            continueRedaction={this.props.continueRedaction}
            toggle={this.toggle}
            jobId={this.props.jobId}
            pause={this.props.pause}
          />
        </Popover>
      </div>
    );
  }
}

class Popoverbody extends React.Component {
  componentDidMount() {
    document.getElementById("body-end-redact-region").value = formattedTimeSMPTE(this.props.num_frames, this.props.fps);

    let start = document.getElementById("body-start-redact-region");
    let end = document.getElementById("body-end-redact-region");

    start.addEventListener("keypress", this.stopPropagation);
    start.addEventListener("keydown", this.stopPropagation);

    end.addEventListener("keypress", this.stopPropagation);
    end.addEventListener("keydown", this.stopPropagation);
  }

  stopPropagation(e) {
    e.stopPropagation();
    e.stopImmediatePropagation();
    e.cancelBubble = true;
  }

  submit_auto_redaction = () => {
    document.getElementById("video").currentTime = 0;
    document.getElementById("video").pause();
    if (this.props) {
      this.props.pause();
    }
    let startTimeStr = document.getElementById("body-start-redact-region").value;
    let endTimeStr = document.getElementById("body-end-redact-region").value;

    if (startTimeStr.indexOf(".") === -1) {
      startTimeStr = startTimeStr + ".";
    }
    if (endTimeStr.indexOf(".") === -1) {
      endTimeStr = endTimeStr + ".";
    }

    let startFrame = toFrames(startTimeStr.replace(".", ":"), this.props.fps);
    let endFrame = toFrames(endTimeStr.replace(".", ":"), this.props.fps);

    // if there is not a frame, add 1 second to the second value, as the user intended to redact through that second
    // nota bene, we only do this for the end time
    if (endTimeStr.indexOf(".") === -1 || endTimeStr.indexOf(".") === endTimeStr.length - 1) {
      endFrame += Math.round(this.props.fps);
      if (endFrame > this.props.num_frames) {
        endFrame = this.props.num_frames;
      }
    }

    if (startFrame >= 0 && endFrame > 0 && startFrame < endFrame && endFrame <= this.props.num_frames) {
      console.log("body auto redaction from " + startFrame + " to " + endFrame);
      if (startTimeStr.slice(-1) === ".") {
        startTimeStr += "0";
      }
      if (endTimeStr.slice(-1) === ".") {
        endTimeStr += "0";
      }

      let period_param = {
        start: startFrame,
        end: endFrame,
        startTimeStr: startTimeStr,
        endTimeStr: endTimeStr,
      };

      this.props.toggle();

      this.props.start_body_auto_redaction(period_param);
    } else {
      if (startFrame < 0) {
        warningNotification("Start time must be 0 or greater");
      } else if (endFrame < 0) {
        warningNotification("End time must be positive");
      } else if (startFrame >= endFrame) {
        warningNotification("End time must be greater than start time");
      } else if (endFrame > this.props.num_frames) {
        warningNotification("End time must be less than or equal to video length");
      }
    }
  };

  render() {
    return (
      <PopoverBody>
        <div id="body-already-redacted-display">
          {this.props.redactedPeriods.length > 0 &&
            this.props.redactedPeriods.map((period, index) =>
              index === 0 ? (
                <span>
                  Already detected bodies from:
                  <li>
                    {period.startTimeStr} to {period.endTimeStr} <br />
                  </li>
                </span>
              ) : (
                <li>
                  {period.startTimeStr} to {period.endTimeStr} <br />
                </li>
              ),
            )}
        </div>
        <Form>
          <FormGroup>
            <Label className="label" for="body-start-redact-region">
              Start:
            </Label>
            <Input type="text" id="body-start-redact-region" defaultValue="0:00:00.00" />
          </FormGroup>
          <FormGroup>
            <Label className="label" for="body-end-redact-region">
              End:
            </Label>
            <Input type="text" id="body-end-redact-region" />
          </FormGroup>
          <FormGroup>
            <Label className="label">Format:</Label>
            <span style={{ fontSize: "12px" }}>H:MM:SS.FF </span>
          </FormGroup>
        </Form>
        <button
          type="button"
          id="redact-button"
          className="btn btn-default btn-block"
          onClick={this.submit_auto_redaction}
        >
          Redact
        </button>
      </PopoverBody>
    );
  }
}
