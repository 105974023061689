export let ui_disabled = false;

export const ui_disable = () => {
  ui_disabled = true;
  console.log('Disabled UI');
};

export const ui_enable = () => {
  ui_disabled = false;
  console.log('Enabled UI');
};
