import React, { Component } from "react";
import volumeHigh from "../../../Icons/volume-high.svg";
import volumeLow from "../../../Icons/volume-low.svg";

class VolumeBar extends Component {
  componentDidMount() {
    this.slider = document.getElementById("volume-bar");
  }

  isCurrentTimeARegion = () => {
    let isInside = false;

    const currentTime = document.getElementById("video").currentTime;
    const regionIds = Object.keys(this.props.wavesurfer.regions.list);

    for (let id of regionIds) {
      const { start, end } = this.props.wavesurfer.regions.list[id];

      if (currentTime >= start && currentTime <= end) {
        isInside = true;
        break;
      }
    }

    return isInside;
  };

  changeVolume = () => {
    const imgIcon = document.getElementById("volume-button-high-low");
    if (this.slider.value <= 0.5) {
      imgIcon.src = volumeLow;
    } else if (this.slider.value > 0.5) {
      imgIcon.src = volumeHigh;
    }

    if (
      this.props.wavesurfer &&
      this.props.wavesurfer.getDuration() > 0 &&
      !this.isCurrentTimeARegion()
    ) {
      this.props.wavesurfer.setVolume(this.slider.value);
    } else {
      this.props.wavesurfer.setVolume(0);
    }
  };

  render() {
    return (
      <input
        id="volume-bar"
        type="range"
        min={0}
        max={1}
        step={0.05}
        defaultValue={1}
        className="vievu-slider"
        onInput={() => {
          this.changeVolume();
        }}
      />
    );
  }
}

export default VolumeBar;
