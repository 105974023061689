import React, { Component } from 'react';

import { videoDisplayResolution } from '../../config/globals';

import spacer from '../../Icons/spacer.gif';

const fetchOriginalRes = (v, metadata) => {
  let ow = videoDisplayResolution.width;
  let oh = videoDisplayResolution.height;

  if (metadata.width) {
    ow = metadata.width;
  } else if (v && v.videoWidth) {
    ow = v.videoWidth;
  }

  if (metadata.height) {
    oh = metadata.height;
  } else if (v && v.videoHeight) {
    oh = v.videoHeight;
  }

  return { ow, oh };
};

const getScaleRatio = (videoWidth, videoHeight) => {
  const maxDisplayWidth = videoDisplayResolution.width;
  const maxDisplayHeight = videoDisplayResolution.height;

  const widthscalefactor = maxDisplayWidth / videoWidth;
  const heightscalefactor = maxDisplayHeight / videoHeight;

  let newWidth;
  let newHeight;

  let scalefactor = 1.0;
  if (videoWidth >= videoHeight) {
    scalefactor = heightscalefactor;
    newWidth = videoWidth * scalefactor;
    if (newWidth > maxDisplayWidth) {
      scalefactor = widthscalefactor;
    }
  } else {
    scalefactor = widthscalefactor;
    newHeight = videoHeight * scalefactor;
    if (newHeight > maxDisplayHeight) {
      scalefactor = heightscalefactor;
    }
  }

  newWidth = videoWidth * scalefactor;
  newHeight = videoHeight * scalefactor;

  return { resultHeight: newHeight, resultWidth: newWidth, scalefactor };
};

export default class VideoFrame extends Component {
  state = {
    videoLoaded: false,
  };

  componentDidUpdate(prevPros) {
    if (prevPros.videoMetadata !== this.props.videoMetadata) {
      this.adjustPlayerResolution();
    }
  }

  adjustPlayerResolution = () => {
    if (!this.state.videoLoaded) {
      return;
    }

    const v = document.getElementById('video');

    // Initially after loading, move the video to 1st frame
    if (v && this.props.fps) {
      v.currentTime = 1 / this.props.fps + 0.00001;
    }

    const canvas = document.getElementById('canvas');
    // const jpgOverlay = document.getElementById("video_jpg_overlay");
    const theaterChild = document.getElementById('theater-child');
    const controlbox = document.getElementById('control-box');
    const videobox = document.getElementById('video-controls');
    const pathSelectorBox = document.getElementById('path-selector-box');
    const sliderBar = document.getElementById('seek-bar');
    const wavesurfer = document.getElementById('waveform');
    const waveTimeline = document.getElementById('wave-timeline');

    const { ow, oh } = fetchOriginalRes(v, this.props.videoMetadata);
    const result = getScaleRatio(ow, oh);

    const height = result.resultHeight;
    const width = result.resultWidth;

    // Setting the scale ratio for other component's usage
    this.props.setScaleRatio(result.scalefactor);

    v.height = height;
    v.width = width;

    canvas.style.height = height + 'px';
    canvas.style.width = width + 'px';

    // jpgOverlay.height = height;
    // jpgOverlay.width = width;

    canvas.style.height = height + 'px';

    theaterChild.style.height = height + 'px';
    theaterChild.style.width = width + 'px';

    controlbox.style.height = height + 'px';
    controlbox.style.left = width + 'px';

    videobox.style.width = width + parseInt(window.getComputedStyle(controlbox).getPropertyValue('width')) + -5 + 'px';
    pathSelectorBox.style.left = width + parseInt(window.getComputedStyle(controlbox).getPropertyValue('width')) + 'px';

    if (width < 600) {
      sliderBar.style.width = '150px';
    } else if (width < 650) {
      sliderBar.style.width = '220px';
      wavesurfer.style.width = '220px';
      waveTimeline.style.width = '220px';
    } else if (width < 830) {
      sliderBar.style.width = '300px';
      wavesurfer.style.width = '300px';
      waveTimeline.style.width = '300px';
    }
  };

  playListener = (e) => {
    const { wavesurfer } = this.props;
    if (!wavesurfer || wavesurfer.getDuration() <= 0) {
      return;
    }

    let currentVideo = e.target;
    if (!currentVideo) {
      currentVideo = document.getElementById('video');
    }

    wavesurfer.on('ready', () => {
      if (currentVideo.currentTime >= 0) {
        wavesurfer.play(currentVideo.currentTime);
      }
    });

    if (currentVideo.currentTime >= 0) {
      wavesurfer.play(currentVideo.currentTime);
    }

    const intervalId = setInterval(() => {
      if (currentVideo.paused || currentVideo.ended) {
        wavesurfer.pause();
        clearInterval(intervalId);
      } else if (currentVideo.readyState < 3) {
        wavesurfer.pause();
      } else {
        const wavesurferTime = wavesurfer.getCurrentTime().toFixed(1);
        const videoTime = currentVideo.currentTime.toFixed(1);

        if (Math.abs(videoTime - wavesurferTime) > 0.3) {
          wavesurfer.seekTo(currentVideo.currentTime / currentVideo.duration);
        }

        if (!wavesurfer.isPlaying()) {
          wavesurfer.play();
        }
      }
    }, 20);
  };

  render() {
    return (
      <span id="theater">
        <span id="theater-child" />
        <video
          id="video"
          ref="video"
          preload="auto"
          muted
          onLoadedMetadata={() => this.setState({ videoLoaded: true })}
          onPlay={this.playListener}
        >
          Your browser does not support the video tag.
        </video>
        <img id="video_jpg_overlay" src={spacer} alt="" height={0} width={0} />
      </span>
    );
  }
}
