import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { black } from "material-ui/styles/colors";

import { ButtonImage } from "../index";
import { ui_disabled, ui_disable, ui_enable } from "../HelperComponents/ui";
import { Position } from "../HelperComponents/Position";
import newRedaction from "../../../Icons/new_redaction_box.svg";

export default class NewRedactionBox extends Component {
  constructor(props) {
    super(props);

    this.tracks = this.props.tracks;
    this.availcolors = ["#7aa644", "#447aa6", "#a6447a", "#ffca49", "#922727", "#b66c4b"];
    this.currentcolor = undefined;
    this.container = undefined;

    this.enabled = false;
    this.drawing = false;

    this.hcrosshair = undefined;
    this.vcrosshair = undefined;

    this.ignoremouseup = false;
    this.startx = 0;
    this.starty = 0;
    this.handle = undefined;

    this.startnewobject = this.startnewobject.bind(this);
    this.drawingnew = this.drawingnew.bind(this);
    this.pickcolor = this.pickcolor.bind(this);
    this.updatecrosshairs = this.updatecrosshairs.bind(this);
    this.respondtoclick = this.respondtoclick.bind(this);
    this.startDrawing = this.startDrawing.bind(this);
    this.updateDrawing = this.updateDrawing.bind(this);
    this.finishDrawing = this.finishDrawing.bind(this);
    this.canceldrawing = this.canceldrawing.bind(this);
    this.stopDrawing = this.stopDrawing.bind(this);
    this.disable = this.disable.bind(this);
    this.cancelobject = this.cancelobject.bind(this);
  }

  componentDidMount() {
    this.container = document.getElementById("canvas");

    document.addEventListener("click", e => {
      e.stopPropagation();
      e.stopImmediatePropagation();
      e.cancelBubble = true;
      if (e.target.id !== "canvas") this.canceldrawing();
    });

    document.addEventListener("keydown", e => {
      // e.preventDefault();
      e.stopPropagation();
      e.cancelBubble = true;
      if (e.keyCode === 27) {
        console.log("esc pressed");
        this.cancelobject();
      }
    });

    document.addEventListener("keypress", e => {
      e.preventDefault();
      if (e.keyCode === 114 || e.keyCode === 82) {
        this.startnewobject();
      }
    });

    this.container.addEventListener("mousemove", this.updatecrosshairs);
    this.container.addEventListener("mousedown", e => {
      this.ignoremouseup = true;
      window.setTimeout(() => {
        this.ignoremouseup = false;
      }, 500);
      this.respondtoclick(e);
    });
    this.container.addEventListener("mouseup", e => {
      if (!this.ignoremouseup) this.respondtoclick(e);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.objready === true && prevProps.objready !== this.props.objready) {
      this.stopnewobj();
    }
  }

  updatecrosshairs(e, visible = true) {
    let xc = e.pageX - this.container.offsetLeft;
    let yc = e.pageY - this.container.offsetTop;
    this.updateDrawing(xc, yc);
    if (this.enabled) {
      if (visible && !this.drawing) {
        this.vcrosshair.style.left = xc + "px";
        this.hcrosshair.style.top = yc + "px";
      } else {
        this.vcrosshair.style.display = "none";
        this.hcrosshair.style.display = "none";
      }
    }
  }

  respondtoclick(e) {
    let xc = e.pageX - this.container.offsetLeft;
    let yc = e.pageY - this.container.offsetTop;

    if (this.enabled) {
      if (!this.drawing) this.startDrawing(xc, yc);
      else this.finishDrawing(xc, yc);
    }
  }

  startnewobject() {
    // console.log("new object")
    if (!this.props.is_video_loaded) {
      console.log("video loading");
      return;
    }
    if (ui_disabled) {
      console.log("UI disabled, can't start a new object");
      return;
    }
    ui_disable();

    this.drawingnew(true);
    console.log("Starting new track object");
    this.currentcolor = this.pickcolor();
    this.container.style.cursor = "crosshair";

    this.enabled = true;

    this.hcrosshair = document.createElement("div");
    this.vcrosshair = document.createElement("div");

    this.container.appendChild(this.hcrosshair);
    this.container.appendChild(this.vcrosshair);

    this.hcrosshair.className += " hcrosshair";
    this.vcrosshair.className += " vcrosshair";

    this.vcrosshair.style.backgroundColor = this.currentcolor;
    this.hcrosshair.style.backgroundColor = this.currentcolor;
  }

  drawingnew(value) {
    for (let i in this.tracks) {
      this.tracks[i].drawingnew = value;
    }
  }

  resizable(value) {}

  pickcolor() {
    return this.availcolors[Math.floor(Math.random() * this.availcolors.length)];
  }

  startDrawing(xc, yc) {
    if (!this.drawing) {
      console.log("START startdrawing( " + xc + ", " + yc + " )");

      this.startx = xc;
      this.starty = yc;
      this.drawing = true;

      this.handle = document.createElement("div"); //$( '<div class="boundingbox"><div>' );
      this.handle.className = "boundingbox";
      this.updateDrawing(xc, yc);
      this.container.appendChild(this.handle);

      // console.log( "END startdrawing( )" );
    }
  }

  updateDrawing(xc, yc) {
    if (this.drawing) {
      let pos = this.calculateposition(xc, yc);

      this.handle.style.top = pos.ytl /*+ this.container.offsetTop*/ + "px";
      this.handle.style.left = pos.xtl + this.container.offsetLeft + "px";
      this.handle.style.width = pos.width + "px";
      this.handle.style.height = pos.height + "px";
      this.handle.style.backgroundColor = black;
      this.handle.style.border = 3 + "px solid " + this.currentcolor;
    }
  }

  calculateposition(xc, yc) {
    let xtl = Math.min(xc, this.startx);
    let ytl = Math.min(yc, this.starty);
    let xbr = Math.max(xc, this.startx);
    let ybr = Math.max(yc, this.starty);

    return new Position(xtl, ytl, xbr, ybr);
  }

  finishDrawing(xc, yc) {
    if (this.drawing) {
      console.log("START finishdrawing( " + xc + ", " + yc + " )");

      var position = this.calculateposition(xc, yc);

      this.drawing = false;
      this.enabled = false;
      // this.container.removeChild(this.handle);
      // this.handle = undefined;

      this.stopDrawing(position);

      this.startx = 0;
      this.starty = 0;

      // console.log( "END finishdrawing( )" );
    }
  }

  canceldrawing() {
    if (this.drawing) {
      console.log("Cancelling drawing");
      this.drawing = false;

      this.container.removeChild(this.handle);

      this.startx = 0;
      this.starty = 0;
    }
  }

  stopDrawing(position) {
    console.log("Received new track object ", position);
    this.props.addTrack(position, this.currentcolor, { automated: false });
    this.props.showBox(true);
  }

  disable() {
    // console.log('disabling draw');
    this.enabled = false;
    this.container.style.cursor = "default";

    if (this.handle) {
      this.container.removeChild(this.handle);
      this.handle = undefined;
    }
    if (this.vcrosshair) {
      this.container.removeChild(this.vcrosshair);
      this.vcrosshair = undefined;
    }
    if (this.hcrosshair) {
      this.container.removeChild(this.hcrosshair);
      this.hcrosshair = undefined;
    }
  }

  stopnewobj() {
    console.log("enabling ui");
    this.disable();

    ui_enable();
    this.drawingnew(false);
    // this.tracks.draggable( true );
    // this.tracks.resizable(true);
    this.props.setobjready(false);
  }

  cancelobject() {
    console.log("Cancelling new track object");
    this.drawing = false;
    this.enabled = false;
    this.disable();
    ui_enable();

    this.drawingnew(false);
    this.props.showBox(false);
    this.props.setobjready(false);
  }

  render() {
    return (
      <div className="command-icon">
        <ButtonImage
          id="new-redaction-box-button"
          onClick={this.startnewobject}
          name={newRedaction}
          imgid="toolbar-new-redaction-box-img"
          data_place="right"
          data_html="true"
          data_tip="Add Manual<br/>Redaction<br/>Object <br/> <br/> Press (R) key"
          class={this.props.is_video_loaded ? "" : "disabled"}
        />
        <ReactTooltip />
      </div>
    );
  }
}
