import axios from 'axios';

import { host } from '../../config/globals';

async function makeRequest(method, url, headers = {}, data = {}, config = {}) {
  try {
    const response = await axios({
      method,
      url,
      headers,
      data,
      ...config,
    });

    return { status: response.status, ...response.data };
  } catch ({ response }) {
    if (response) {
      return { status: response.status, ...response.data };
    }
    return { error: 'Network failure' };
  }
}

export const getJobs = () => {
  return makeRequest('GET', `${host()}/suspect/jobs`);
};

export const deleteJob = (qname = null, jobID = null) => {
  return makeRequest('DELETE', `${host()}/suspect/jobs`, null, { qname, jobID });
};
