import React, { Component } from "react";
import path from "path";
import $ from "jquery";

import { host } from "../../../config/globals";

const makeUrl = require("make-url");

export default class Waves extends Component {
  constructor(props) {
    super(props);
    this.video = undefined;
    this.fps = undefined;
    this.seek_bar = undefined;
    this.imgPath = undefined;
    this.wavesurferClicked = false;
  }

  componentDidMount() {
    this.fps = this.props.fps;
    this.video = document.getElementById("video");
    this.seek_bar = document.getElementById("seek-bar");
  }

  componentDidUpdate() {
    if (this.props.metadata) {
      this.imgPath = makeUrl(this.props.metadata + "/img/", path.join(this.props.metadata));
    }
    this.fps = this.props.fps;
    this.video = document.getElementById("video");
    this.seek_bar = document.getElementById("seek-bar");
    if (this.props.wavesurfer) {
      this.props.wavesurfer.on("seek", this.updateSeekbar);
    }
  }

  updateSeekbar = (progress) => {
    try {
      if (!this.wavesurferClicked || isNaN(progress)) {
        return;
      }

      this.wavesurferClicked = false;
      this.seek_bar.value = progress * 100;
      this.video.currentTime = this.video.duration * progress;

      if (!this.video.paused) {
        return;
      }
      const frame_seek = Math.floor(this.video.duration * progress * this.fps);
      this.move_to_frame(frame_seek);
    } catch (err) {
      console.log(err);
    }
  };

  move_to_frame = (frame) => {
    if (isNaN(frame) || frame <= 0 || frame > this.props.num_frames) {
      return;
    }
    let url = new URL(`${host()}/suspect/static${this.props.metadata}/imgs/${frame}.jpg`);

    $("#video_jpg_overlay").attr("src", url);
  };

  handleWavesurferClick = () => {
    this.wavesurferClicked = true;

    setTimeout(() => {
      if (this.wavesurferClicked) {
        this.updateSeekbar(); // To manually update seekbar, if wavesurfer seek listener executed first
      }
    }, 200);
  };

  render() {
    return (
      <div style={{ position: "relative" }}>
        <div id="waveform" onClick={this.handleWavesurferClick} />
        <div id="wave-timeline" />
      </div>
    );
  }
}
