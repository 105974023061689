import React, { useEffect } from 'react';
import $ from 'jquery';

import 'jquery-ui';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/resizable';
import 'jquery-ui/themes/base/draggable.css';
import 'jquery-ui/themes/base/resizable.css';

import { Box } from '../utils/trackers';
import { blurOptions } from '../../../config/globals';
import TrackCollection from '../utils/TrackCollection';

import visibleIcon from '../../../Icons/visible.padded.svg';
import notVisibleIcon from '../../../Icons/not-visible.padded.svg';

export default function InteractionBoxes(props) {
  const { video } = props;
  let playInterval;

  useEffect(() => {
    if (!video) {
      return;
    }

    updateframe(0);
    video.addEventListener('timeupdate', timeUpdateListener);
    if (props.fps) {
      video.addEventListener('play', playListener);
    }

    return () => {
      video.removeEventListener('timeupdate', timeUpdateListener);
      video.removeEventListener('play', playListener);

      if (playInterval) {
        clearInterval(playInterval);
      }
    };
    // eslint-disable-next-line
  }, [props.fps, props.uiUpdated, props.blur, props.video]);

  function timeUpdateListener(e) {
    if (e.target.paused) {
      updateframe(0);
    }
  }

  function playListener(e) {
    let intervalTime = parseInt(1000 / props.fps, 10);
    intervalTime = intervalTime < 20 ? intervalTime : 20;

    playInterval = setInterval(() => {
      if (e.target.paused) {
        clearInterval(playInterval);
      } else {
        updateframe(1);
      }
    }, intervalTime);
  }

  function updateframe(frameNormalize = 0) {
    const frame = Math.floor(video.currentTime * props.fps) + frameNormalize;

    TrackCollection.tracks.map((track) => {
      const currentBox = $(`#boundingbox_${track.id}`);
      if (!currentBox) {
        return null;
      }

      const eyeIcon = $(`#trackobject_${track.id}_visible_icon`);

      const box = TrackCollection.getTrackBox(track, frame);
      if (!box) {
        currentBox.css({ display: 'none' });

        if (eyeIcon && track.currentlyVisible) {
          eyeIcon.attr('src', notVisibleIcon);
        }

        if (track.currentlyVisible) {
          track.updateCurrentlyVisible(false);
        }

        return null;
      }

      currentBox.css({
        top: `${box.ytl}px`,
        left: `${box.xtl}px`,
        width: `${box.width}px`,
        height: `${box.height}px`,
        borderColor: track.color,
        display: 'block',
      });

      if (eyeIcon && !track.currentlyVisible) {
        eyeIcon.attr('src', visibleIcon);
      }

      if (!track.currentlyVisible) {
        track.updateCurrentlyVisible(true);
      }

      return track.id;
    });
  }

  return TrackCollection.tracks.map((track) => (
    <JsBox key={track.id} track={track} fps={props.fps} updateUI={props.updateUI} blur={props.blur} />
  ));
}

function JsBox(props) {
  const { track } = props;

  useEffect(() => {
    const handle = $(`#boundingbox_${props.track.id}`);
    handle.resizable({
      containment: document.getElementById('canvas'),
      handles: 'all',
      stop: setPosition,
    });

    handle.draggable({
      containment: document.getElementById('canvas'),
      stop: setPosition,
    });
    // eslint-disable-next-line
  }, [props.fps]);

  function setPosition() {
    const finalOffset = $(`#boundingbox_${props.track.id}`).position();
    const newBox = document.getElementById(`boundingbox_${props.track.id}`);

    const x1 = finalOffset.left;
    const y1 = finalOffset.top;
    const x2 = x1 + newBox.offsetWidth;
    const y2 = y1 + newBox.offsetHeight;
    const isManual = track.type === 'manual';

    const modifiedBox = new Box(x1, y1, x2, y2, true, isManual);
    const video = document.getElementById('video');
    const currentFrameID = Math.floor(video.currentTime * props.fps);
    track.update(currentFrameID, modifiedBox);

    props.updateUI();
  }

  let blurStyle = 'none';
  if (props.blur === blurOptions.smooth) {
    blurStyle = 'blur(10px)';
  } else if (props.blur === blurOptions.mask) {
    blurStyle = 'blur(5px)';
  }

  return (
    <div
      className="boundingbox"
      id={`boundingbox_${track.id}`}
      style={{
        display: 'none',
        backdropFilter: blurStyle,
        background: props.blur === blurOptions.solid ? '#989797' : 'transparent',
      }}
    >
      <div className="boundingboxtext">
        <strong>
          {track.label}
          {track.type !== 'auto' && <sup>*</sup>}
        </strong>
      </div>
    </div>
  );
}
