const React = require("react");

// import './styles/playbackRate.css'

export default class PlaybackRate extends React.Component {
  constructor(props) {
    super(props);
    this.setPlaybackRate = this.setPlaybackRate.bind(this);
  }

  componentDidMount() {
    this.video = document.getElementById("video");
    document.addEventListener("keypress", this.keyPress);
  }

  componentWillUnmount() {
    document.removeEventListener("keypress", this.keyPress);
  }

  keyPress = e => {
    e.preventDefault();

    let code = e.keyCode;
    let pauseTime;
    let intervalId;
    let rewindBy;
    let rewind = false;
    if (e.keyCode === 86 || e.keyCode === 118) {
      // v
      this.props.pause();
      rewind = true;
      rewindBy = 0.5;
    }
    if (e.keyCode === 66 || e.keyCode === 98) {
      // b
      this.props.pause();
      rewind = true;
      rewindBy = 1.0;
    }
    if (e.keyCode === 78 || e.keyCode === 110) {
      // n
      this.props.pause();
      rewind = true;
      rewindBy = 3.0;
    }
    if (e.keyCode === 77 || e.keyCode === 109) {
      // m
      this.props.pause();
      rewind = true;
      rewindBy = 5.0;
    }

    if (rewind) {
      pauseTime = this.video.currentTime;
      this.video.currentTime = this.video.currentTime - rewindBy;
      code = 49;
      intervalId = setInterval(() => {
        if (this.video.paused) {
          clearInterval(intervalId);
        } else if (this.video.currentTime >= pauseTime) this.props.pause();
      }, 25);
      this.props.play();
    }

    switch (code) {
      case 49:
        this.setPlaybackRate(e, "playback_speed_selector_.25x");
        break;
      case 50:
        this.setPlaybackRate(e, "playback_speed_selector_.5x");
        break;
      case 51:
        this.setPlaybackRate(e, "playback_speed_selector_1x");
        break;
      case 52:
        this.setPlaybackRate(e, "playback_speed_selector_2x");
        break;
      case 53:
        this.setPlaybackRate(e, "playback_speed_selector_4x");
        break;
      default:
    }
  };

  setPlaybackRate(e, key = undefined) {
    console.log(e);
    // e.preventDefault();
    let val = e.target.id;
    if (key) {
      val = key;
    }
    // infoLogger.info(e,key,val,__filename)
    switch (val) {
      case "playback_speed_selector_4x":
        this.video.playbackRate = 4.0;
        if (this.props.wavesurfer && this.props.wavesurfer.getDuration() > 0) {
          this.props.wavesurfer.setPlaybackRate(4.0);
        }
        document.getElementById("playback_speed_indicator").innerHTML = "4x";
        break;

      case "playback_speed_selector_2x":
        this.video.playbackRate = 2.0;
        if (this.props.wavesurfer && this.props.wavesurfer.getDuration() > 0) {
          this.props.wavesurfer.setPlaybackRate(2.0);
        }
        document.getElementById("playback_speed_indicator").innerHTML = "2x";
        break;

      case "playback_speed_selector_1x":
        this.video.playbackRate = 1.0;
        if (this.props.wavesurfer && this.props.wavesurfer.getDuration() > 0) {
          this.props.wavesurfer.setPlaybackRate(1.0);
        }
        document.getElementById("playback_speed_indicator").innerHTML = "1x";
        break;

      case "playback_speed_selector_.5x":
        this.video.playbackRate = 0.5;
        if (this.props.wavesurfer && this.props.wavesurfer.getDuration() > 0) {
          this.props.wavesurfer.setPlaybackRate(0.5);
        }
        document.getElementById("playback_speed_indicator").innerHTML = ".5x";
        break;

      case "playback_speed_selector_.25x":
        this.video.playbackRate = 0.25;
        if (this.props.wavesurfer && this.props.wavesurfer.getDuration() > 0) {
          this.props.wavesurfer.setPlaybackRate(0.25);
        }
        document.getElementById("playback_speed_indicator").innerHTML = ".25x";
        break;
      default:
    }
    // infoLogger.info('playbackrate set to: ', this.video.playbackRate,__filename)
  }

  render() {
    return (
      <div className="dropdown">
        <button
          type="button"
          className="btn dropdown-toggle"
          data-toggle="dropdown"
        >
          <span id="playback_speed_indicator">1x</span>
          <span className="caret" />
        </button>
        <div
          className="dropdown-menu"
          id="playback_speed_selector"
          onClick={this.setPlaybackRate}
        >
          <li>
            {/* eslint-disable-next-line */}
            <a className="dropdown-item" id="playback_speed_selector_4x">
              4x
            </a>
          </li>
          <li>
            {/* eslint-disable-next-line */}
            <a className="dropdown-item" id="playback_speed_selector_2x">
              2x
            </a>
          </li>
          <li>
            {/* eslint-disable-next-line */}
            <a className="dropdown-item" id="playback_speed_selector_1x">
              Normal
            </a>
          </li>
          <li>
            {/* eslint-disable-next-line */}
            <a className="dropdown-item" id="playback_speed_selector_.5x">
              .5x
            </a>
          </li>
          <li>
            {/* eslint-disable-next-line */}
            <a className="dropdown-item" id="playback_speed_selector_.25x">
              .25x
            </a>
          </li>
        </div>
      </div>
    );
  }
}
