import React, { Component } from "react";
import $ from "jquery";

import { ButtonImage } from "../index";
import volumeHigh from "../../../Icons/volume-high.svg";
import volumeLow from "../../../Icons/volume-low.svg";

class VolumeButton extends Component {
  isCurrentTimeARegion = () => {
    let isInside = false;

    const currentTime = document.getElementById("video").currentTime;
    const regionIds = Object.keys(this.props.wavesurfer.regions.list);

    for (let id of regionIds) {
      const { start, end } = this.props.wavesurfer.regions.list[id];

      if (currentTime >= start && currentTime <= end) {
        isInside = true;
        break;
      }
    }

    return isInside;
  };

  toggle = () => {
    const imgIcon = document.getElementById("volume-button-high-low");
    const volumeBar = document.getElementById("volume-bar");
    if (volumeBar.value >= 0.5) {
      imgIcon.src = volumeLow;
      $("#volume-bar").val(0);
      if (this.props.wavesurfer && this.props.wavesurfer.getDuration() > 0) {
        this.props.wavesurfer.setVolume(0);
      }
    } else {
      imgIcon.src = volumeHigh;
      $("#volume-bar").val(1);

      if (
        this.props.wavesurfer &&
        this.props.wavesurfer.getDuration() > 0 &&
        !this.isCurrentTimeARegion()
      ) {
        this.props.wavesurfer.setVolume(1);
      } else {
        this.props.wavesurfer.setVolume(0);
      }
    }
  };

  render() {
    return (
      <ButtonImage
        onClick={() => {
          try {
            this.toggle();
          } catch (e) {
            console.log(e);
          }
        }}
        name={volumeHigh}
        id="volume-button"
        imgid="volume-button-high-low"
        style={style}
      />
    );
  }
}

export default VolumeButton;

const style = {
  height: 32,
  verticalAlign: "baseline"
};
