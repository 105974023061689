import React from 'react';
import { render } from 'react-dom';

import './app.global.css';
import Routes from './routes';

require('dotenv').config();
require('./styles/styles.global.scss');

render(<Routes />, document.getElementById('root'));
