const React = require('react');

const { formattedTimeSMPTE } = require('./toSMPTE');

export default class Duration extends React.Component {
  constructor(props) {
    super(props);
    this.video = undefined;
    this.time_elapsed = undefined;
    this.intervalId = undefined;
    this.set_time_elapsed = this.set_time_elapsed.bind(this);
  }

  componentDidMount() {
    this.time_elapsed = document.getElementById('time-elapsed');
    this.video = document.getElementById('video');
    this.setListener();
  }

  componentDidUpdate() {
    this.set_time_elapsed();
  }

  setListener() {
    this.video.addEventListener('play', () => {
      this.intervalId = setInterval(() => {
        if (this.video.paused || this.video.ended) {
          clearInterval(this.intervalId);
        } else {
          this.update_time_elapsed();
        }
      }, 1);
    });

    this.video.addEventListener('timeupdate', () => {
      this.update_time_elapsed();
    });

    this.video.addEventListener('ended', () => {
      this.update_time_elapsed();
      this.video.currentTime = 0;
      if (this.props.wavesurfer && this.props.wavesurfer.getDuration() > 0) {
        this.props.wavesurfer.seekTo(0);
      }
    });
  }

  update_time_elapsed(options) {
    let t = this.video.currentTime;
    if (options && options['time']) {
      t = options['time'];
    } else {
      t = this.video.currentTime;
    }

    if ((t || t === 0) && this.video.duration) {
      let currentFrame = Math.floor(this.video.currentTime * this.props.fps);

      if (currentFrame > this.props.num_frames) {
        currentFrame = this.props.num_frames;
      }
      this.time_elapsed.innerHTML =
        formattedTimeSMPTE(currentFrame, this.props.fps) +
        ' / ' +
        formattedTimeSMPTE(this.props.num_frames, this.props.fps);
    }
  }

  set_time_elapsed() {
    if (this.video.duration) this.update_time_elapsed();
    else setTimeout(this.set_time_elapsed, 500);
  }

  render() {
    return <span id="time-elapsed"> 0:00:00.01 / 0:00:00.01 </span>;
  }
}
