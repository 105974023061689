import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import RedactVideo from './components/VideoPlayer/FaceComponents/RedactVideo';
import Stats from './components/Jobs';

export default () => (
  <Router>
    <Switch>
      <Route exact path="/" component={RedactVideo} />
      <Route exact path="/jobs" component={Stats} />
    </Switch>
  </Router>
);
