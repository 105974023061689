export const wrap = n => (n < 10 ? `0${n}` : n);

export const formattedTimeSMPTE = (frame, fps) => {
  if (frame === 0) {
    return '0:00:00.00';
  }

  const frameNumber = Number(frame);
  const hour = fps * 60 * 60;
  const minute = fps * 60;

  const hours = parseInt(frameNumber / hour, 10);
  const minutes = parseInt((frameNumber / minute) % 60, 10);
  const seconds = parseInt((frameNumber / fps) % 60, 10);

  const SMPTE = `${hours}:${wrap(minutes)}:${wrap(seconds)}.${wrap(
    Math.round(frameNumber % fps)
  )}`;

  return SMPTE;
};
