import React, { Component } from 'react';

import {
  NewRedactionBox,
  NewFaceAutoRedaction,
  NewBodyAutoRedaction,
  ToolbarMergePaths,
  ToolbarRedactAudio,
  Settings,
  KeyboardShortcuts,
} from './index';

export default class Sidebar extends Component {
  render() {
    return (
      <div className="controls" id="control-box">
        <NewRedactionBox
          is_video_loaded={this.props.is_video_loaded}
          showBox={this.props.showBox}
          objready={this.props.objready}
          tracks={this.props.tracks}
          setobjready={this.props.setobjready}
          addTrack={this.props.addTrack}
          jobId={this.props.jobId}
        />
        <NewFaceAutoRedaction
          enableUI={this.props.enableUI}
          disableUI={this.props.disableUI}
          is_video_loaded={this.props.is_video_loaded}
          fps={this.props.fps}
          num_frames={this.props.num_frames}
          metadataLoc={this.props.metadataLoc}
          play={this.props.play}
          pause={this.props.pause}
          wavesurfer={this.props.wavesurfer}
          continueRedaction={this.props.continueRedaction}
          jobId={this.props.jobId}
        />
        <NewBodyAutoRedaction
          enableUI={this.props.enableUI}
          disableUI={this.props.disableUI}
          is_video_loaded={this.props.is_video_loaded}
          fps={this.props.fps}
          num_frames={this.props.num_frames}
          metadataLoc={this.props.metadataLoc}
          play={this.props.play}
          pause={this.props.pause}
          wavesurfer={this.props.wavesurfer}
          continueRedaction={this.props.continueRedaction}
          jobId={this.props.jobId}
        />
        <ToolbarMergePaths
          is_video_loaded={this.props.is_video_loaded}
          fps={this.props.fps}
          num_frames={this.props.num_frames}
          metadataLoc={this.props.metadataLoc}
          tracks={this.props.tracks}
          deleteTrack={this.props.deleteTrack}
          jobId={this.props.jobId}
          updateUI={this.props.updateUI}
          uiUpdated={this.props.uiUpdated}
          scaleRatio={this.props.scaleRatio}
        />

        <ToolbarRedactAudio
          is_video_loaded={this.props.is_video_loaded}
          fps={this.props.fps}
          num_frames={this.props.num_frames}
          metadataLoc={this.props.metadataLoc}
          wavesurfer={this.props.wavesurfer}
          mergeAudio={this.props.mergeAudio}
          jobId={this.props.jobId}
          videoMetadata={this.props.videoMetadata}
        />

        <Settings
          is_video_loaded={this.props.is_video_loaded}
          restore_metadata={this.props.restore_metadata}
          metadataLoc={this.props.metadataLoc}
          wavesurfer={this.props.wavesurfer}
          jobId={this.props.jobId}
          blur={this.props.blur}
          setBlur={this.props.setBlur}
        />
        <KeyboardShortcuts is_video_loaded={this.props.is_video_loaded} jobId={this.props.jobId} />
      </div>
    );
  }
}
