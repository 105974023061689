import React, { Component } from 'react';
import $ from 'jquery';

export default class AudioComponent extends Component {
  constructor(props) {
    super(props);
    this.zoomLevel = 0;
  }

  componentDidMount() {
    $(document).ready(() => {
      const audio_zoom_plus_button = document.getElementById('audio_zoom_plus_box');
      const audio_zoom_minus_button = document.getElementById('audio_zoom_minus_box');

      audio_zoom_plus_button.addEventListener('click', () => {
        this.zoomLevel += 4;
        if (this.props.wavesurfer && this.props.wavesurfer.getDuration() > 0) {
          this.props.wavesurfer.zoom(this.zoomLevel);
        }

        // doesn't die when wave-minimap is disabled
        if (document.getElementById('wave-minimap')) {
          document.getElementById('wave-minimap').style.display = 'initial';
        }
      });

      audio_zoom_minus_button.addEventListener('click', () => {
        this.zoomLevel -= 4;

        if (this.zoomLevel <= 1) {
          this.zoomLevel = 1;
        }

        if (this.props.wavesurfer && this.props.wavesurfer.getDuration() > 0) {
          this.props.wavesurfer.zoom(this.zoomLevel);
        }
      });
    });
  }
  render() {
    return (
      <div className="audiocomponent">
        <div
          id="wavesurfer-zoom-buttons"
          style={{
            position: 'relative',
            left: 23,
            width: 40,
          }}
        >
          <div
            id="audio_zoom_plus_box"
            className="audio-zoom"
            style={{
              textAlign: 'center',
              width: 30,
              height: 30,
              borderRadius: 8,
              borderWidth: 1,
              borderStyle: 'solid',
              fontSize: 20,
              position: 'relative',
              top: -8,
              cursor: 'pointer',
            }}
          >
            <svg
              id="audio_zoom_plus"
              className="icon icon-plus"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              style={{ verticalAlign: 'initial' }}
            >
              <path d="M8 4 h3 v5 h5 v3 h-5 v5 h-3 v-5 h-5 v-3 h5 z" />
            </svg>
          </div>

          <div
            className="audio-search"
            style={{
              textAlign: 'center',
              position: 'relative',
              left: -4,
            }}
          >
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: 'initial' }}>
              <path
                id="hellopath"
                d="M15.5 14h-.79l-.28-.27c.98-1.14 1.57-2.62 1.57-4.23 0-3.59-2.91-6.5-6.5-6.5s-6.5 2.91-6.5 6.5 2.91 6.5 6.5 6.5c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99 1.49-1.49-4.99-5zm-6 0c-2.49 0-4.5-2.01-4.5-4.5s2.01-4.5 4.5-4.5 4.5 2.01 4.5 4.5-2.01 4.5-4.5 4.5z"
              />
            </svg>
          </div>
          <div
            id="audio_zoom_minus_box"
            className="audio-zoom"
            style={{
              textAlign: 'center',
              width: 30,
              height: 30,
              borderRadius: 8,
              border: 1,
              borderStyle: 'solid',
              fontSize: 20,
              cursor: 'pointer',
            }}
          >
            <svg
              id="audio_zoom_minus"
              className="icon icon-minus"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              style={{ verticalAlign: 'initial' }}
            >
              <path d="M4 8 h12 v3 h-12 v-3 z" />
            </svg>
          </div>
        </div>
      </div>
    );
  }
}
