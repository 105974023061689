import React, { Component, useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, Table } from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";

import { ButtonImage } from "../index";
import mergeIcon from "../../../Icons/merge.svg";
import { host } from "../../../config/globals";

import TrackCollection from "../utils/TrackCollection";
import { warningNotification } from "../ExternalComponents/Notifications";

export default class ToolbarMergePaths extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalStatus: false,
    };
  }

  toggle = () => {
    this.setState(() => ({
      modalStatus: !this.state.modalStatus,
    }));
  };

  render() {
    return (
      <div className="command-icon" id="toolbar-merge-paths">
        <ButtonImage
          id="toolbar-mergepath-button"
          imgid="toolbar-merge-paths-img"
          name={mergeIcon}
          data_place="right"
          data_tip="Merge Redaction Paths"
          class={this.props.is_video_loaded ? "" : "disabled"}
          onClick={this.toggle}
        />
        <Modal isOpen={this.state.modalStatus} toggle={this.toggle} id="merge-paths-modal">
          <ModalHeader toggle={this.toggle} />
          <ModalBody id="merge-paths-modal-body">
            {TrackCollection.tracks.length === 0 ? (
              <span style={{ fontSize: 24, color: "#9f9f9f" }}>
                {" "}
                No objects to merge <br />
                <br />
                <br />
              </span>
            ) : (
              <Body
                toggle={this.toggle}
                metadata={this.props.metadataLoc}
                jobId={this.props.jobId}
                updateUI={this.props.updateUI}
                uiUpdated={this.props.uiUpdated}
                scaleRatio={this.props.scaleRatio}
              />
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const numTracksToAdd = 100;
let lastIndex = 100;

function Body(props) {
  const [scrollTracks, setScrollTracks] = useState(TrackCollection.tracks.slice(0, numTracksToAdd));
  const [hasMore, setHasMore] = useState(true);

  const [selectedTracks, setSelectedTracks] = useState([]);

  useEffect(() => {
    updateScrollTracks();
  }, [props.uiUpdated]);

  function updateScrollTracks() {
    setScrollTracks(TrackCollection.tracks.slice(0, lastIndex));
  }

  function fetchTracks() {
    const tracksToAppend = TrackCollection.tracks.slice(lastIndex, lastIndex + numTracksToAdd);

    lastIndex += numTracksToAdd;
    setScrollTracks((prevTracks) => [...prevTracks, ...tracksToAppend]);
    setHasMore(lastIndex <= TrackCollection.tracks.length);
  }

  function mergePathSubmit() {
    if (selectedTracks.length < 2) {
      warningNotification("You must select at least two paths for merging");
    } else if (selectedTracks.length > 2) {
      warningNotification("Only two paths are allowed to be selected");
    } else {
      const sortedTracks = selectedTracks.sort((t1, t2) => t1.id - t2.id);
      TrackCollection.merge(sortedTracks);
      props.updateUI();
      props.toggle();
    }
  }

  function addToSelectedTracks(track) {
    setSelectedTracks((prevTracks) => [...prevTracks, track]);
  }

  function removeFromSelectedTracks(track) {
    setSelectedTracks((prevTracks) => prevTracks.filter((selTrack) => selTrack.id !== track.id));
  }

  return (
    <div>
      <span style={{ fontSize: 24, color: "#9f9f9f" }}>
        Select paths to merge:
        <br />
        <br />
      </span>
      <div id="merge-box">
        <InfiniteScroll
          dataLength={scrollTracks.length}
          next={fetchTracks}
          hasMore={hasMore}
          scrollableTarget="merge-box"
        >
          {scrollTracks.map((track) => (
            <Track
              key={track.id}
              track={track}
              metadata={props.metadata}
              scaleRatio={props.scaleRatio}
              addToSelectedTracks={addToSelectedTracks}
              removeFromSelectedTracks={removeFromSelectedTracks}
            />
          ))}
        </InfiniteScroll>
      </div>
      <br />
      <button type="button" id="merge-paths-modal-cancel" className="btn" onClick={props.toggle}>
        Cancel
      </button>
      <button type="button" id="merge-paths-submit-button" className="btn btn-primary" onClick={mergePathSubmit}>
        Merge
      </button>
    </div>
  );
}

class Track extends React.Component {
  componentDidMount() {
    const allVisibleFrames = [];
    for (let frame in this.props.track.boxes) {
      if (this.props.track.boxes[frame].visible) {
        allVisibleFrames.push(frame);
      }
    }

    let firstVisibleFrameID = Math.min(...allVisibleFrames);
    if (firstVisibleFrameID === Infinity || firstVisibleFrameID === 0) {
      firstVisibleFrameID = 1;
    }

    const { xtl, ytl, xbr, ybr } = this.props.track.boxes[firstVisibleFrameID];
    const video = document.getElementById("video");
    const raw_width = xbr / this.props.scaleRatio - xtl / this.props.scaleRatio;
    const raw_height = ybr / this.props.scaleRatio - ytl / this.props.scaleRatio;
    var obj_width = 100;
    var obj_height = 100;
    var scale_factor = 1.0;
    if (raw_width > raw_height) {
      scale_factor = 100 / raw_width;
      obj_height = Math.floor(raw_height * scale_factor);
    } else {
      scale_factor = 100 / raw_height;
      obj_width = Math.floor(raw_width * scale_factor);
    }
    const url = new URL(`${host()}/suspect/static${this.props.metadata}/imgs/${firstVisibleFrameID}.jpg`);
    const canvas = document.getElementById("merge-box_image" + this.props.track.id);
    canvas.height = obj_height;
    canvas.width = obj_width;

    const image = new Image();
    image.width = video.offsetWidth;
    image.height = video.offsetHeight;
    image.src = url;
    image.addEventListener("load", () => {
      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        image,
        xtl / this.props.scaleRatio,
        ytl / this.props.scaleRatio,
        raw_width,
        raw_height,
        0,
        0,
        obj_width,
        obj_height,
      );
    });
    image.remove();
  }

  handleSelect = (e) => {
    if (e.target.checked) {
      this.props.addToSelectedTracks(this.props.track);
    } else {
      this.props.removeFromSelectedTracks(this.props.track);
    }
  };

  render() {
    return (
      <div>
        <div className="merge-box_element">
          <Table>
            <tbody>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    id={"object_" + this.props.track.id + "_merge_checkbox"}
                    onChange={this.handleSelect}
                  />
                </td>
                <td>
                  <label className="merge-box_labels" htmlFor="object_0_merge_checkbox">
                    {this.props.track.label}
                    {this.props.track.type === "manual" && <sup>*</sup>}
                  </label>
                </td>
                <td>
                  <div style={{ width: 100 }}>
                    <canvas id={"merge-box_image" + this.props.track.id}></canvas>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}
