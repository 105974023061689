import React, { useEffect, useState } from "react";
import $ from "jquery";
import InfiniteScroll from "react-infinite-scroll-component";

import spacer from "../../../Icons/spacer.gif";
import deleteIcon from "../../../Icons/delete.svg";
import visibleIcon from "../../../Icons/visible.padded.svg";
import notVisibleIcon from "../../../Icons/not-visible.padded.svg";

import TrackCollection from "../utils/TrackCollection";

require("jquery-ui");

const numTracksToRender = 100;
let currentLastIndex = 100;

export default function PathSelectorBox(props) {
  const [scrollTracks, setScrollTracks] = useState(TrackCollection.tracks.slice(0, numTracksToRender));
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    updateScrollTracks();
    if (TrackCollection.tracks.length > 0) {
      $("#loading-saved-data").remove();
    }
  }, [props.uiUpdated]);

  function updateScrollTracks() {
    setScrollTracks(TrackCollection.tracks.slice(0, currentLastIndex));
  }

  function fetchTracks() {
    const tracksToAppend = TrackCollection.tracks.slice(currentLastIndex, currentLastIndex + numTracksToRender);

    currentLastIndex += numTracksToRender;
    setScrollTracks((prevTracks) => [...prevTracks, ...tracksToAppend]);
    setHasMore(currentLastIndex <= TrackCollection.tracks.length);
  }

  return (
    <div id="path-selector-box">
      <div id="path-selector-box-objects">
        {props.show && <SelectionBox hideBox={props.hideBox} track={props.track} setobjready={props.setobjready} />}
      </div>
      <InfiniteScroll
        dataLength={scrollTracks.length}
        next={fetchTracks}
        hasMore={hasMore}
        scrollableTarget="path-selector-box"
      >
        {scrollTracks.map((track) => (
          <TrackObject
            key={track.id}
            track={track}
            fps={props.fps}
            num_frames={props.num_frames}
            pause={props.pause}
            setFrame={props.setFrame}
            wavesurfer={props.wavesurfer}
            uiUpdated={props.uiUpdated}
            updateUI={props.updateUI}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
}

PathSelectorBox.defaultProps = {
  show: false,
};

class SelectionBox extends React.Component {
  constructor(props) {
    super(props);

    this.track = this.props.track;
    this.id = this.track.id;

    this.labels = ["Person", "Vehicle", "Custom"];
    this.attributes = { 0: {}, 1: {}, 2: {} };

    // this.initialize = this.initialize.bind(this);
    this.keydown = this.keydown.bind(this);
    this.Classify = this.Classify.bind(this);
    this.finalize = this.finalize.bind(this);
  }

  componentDidMount() {
    const customLabel = document.getElementById("customLabel");
    customLabel.addEventListener("keydown", this.keydown);
    customLabel.addEventListener("keypress", this.keyPress);
  }

  componentWillUnmount() {
    const customLabel = document.getElementById("customLabel");
    customLabel.removeEventListener("keydown", this.keydown);
    customLabel.removeEventListener("keypress", this.keyPress);
  }

  keyPress = (e) => {
    e.stopPropagation();
    e.stopImmediatePropagation();
    e.cancelBubble = true;
  };

  keydown(e) {
    if (e.keyCode === 13) {
      this.finalize(2, document.getElementById("customLabel").value);
    }
    e.stopPropagation();
    e.stopImmediatePropagation();
    e.cancelBubble = true;
  }

  Classify(e) {
    const labelid = this.labels.indexOf(e.target.value);
    if (labelid !== this.labels.length - 1 && labelid !== -1) {
      this.finalize(labelid);
    }
  }

  finalize(labelid, label = "") {
    let id = this.id;

    this.track.labelid = labelid;
    this.track.label =
      labelid === this.labels.length - 1 ? `${label} ${id - 10000}` : `${this.labels[labelid]} ${id - 10000}`;

    this.track.visible = true;
    this.track.ready = true;
    this.props.setobjready(true);
    this.props.hideBox(false);
  }

  render() {
    let name = "classification" + this.id;
    return (
      <div className="trackobject">
        <div id="box">
          <p>What type of object did you just mark?</p>
          {this.labels.map((label, index) => (
            <div key={label} className="label">
              <input type="radio" name={name} id={name + "_" + index} value={label} onClick={this.Classify} /> {label}
              <br />
            </div>
          ))}
          <input id="customLabel" />
        </div>
      </div>
    );
  }
}

class TrackObject extends React.Component {
  constructor(props) {
    super(props);

    this.track = this.props.track;
    this.delete = this.delete.bind(this);
    this.toggleDisplay = this.toggleDisplay.bind(this);
    this.Click = this.Click.bind(this);
  }

  componentDidMount() {
    const boxBorder = document.getElementById(`box_border${this.track.id}`);
    if (boxBorder) {
      boxBorder.style.borderColor = this.track.color;
    }

    if (this.track.selected === true) {
      const redactionBox = document.getElementById(`redaction-object${this.track.id}`);
      if (redactionBox) {
        const arr = redactionBox.className.split(" ");
        if (arr.indexOf("trackobject-selected") === -1) {
          redactionBox.className = `${redactionBox.className} trackobject-selected`;
        }
      }
    }

    if (this.track.currentlyVisible) {
      const eyeIcon = document.getElementById(`trackobject_${this.track.id}_visible_icon`);
      if (eyeIcon) eyeIcon.src = visibleIcon;
    }
  }

  delete() {
    // To show the label of manual redaction 10000 less than the actual value(10000 is the assumed starting value of manual redaction)
    let trackIdToBeDeleted;
    if (this.track.type === "auto") {
      trackIdToBeDeleted = this.track.label;
    } else {
      trackIdToBeDeleted = this.track.label + "*";
    }

    if (
      window.confirm(
        "Delete the " +
          trackIdToBeDeleted +
          " track? If the object just left the view screen, click the 'eye' icon instead.",
      )
    ) {
      TrackCollection.delete(this.track.id);
      this.props.updateUI();
    }
  }

  toggleDisplay() {
    this.props.pause();

    const currentBox = document.getElementById(`boundingbox_${this.track.id}`);
    const eyeIcon = document.getElementById(`trackobject_${this.track.id}_visible_icon`);

    let status = false;
    if (this.track.currentlyVisible) {
      status = false;
      if (eyeIcon) {
        eyeIcon.src = notVisibleIcon;
      }

      if (currentBox) {
        currentBox.style.display = "none";
      }

      // Used to close eyeIcon when infinite scrolling
      this.track.updateCurrentlyVisible(false);
    } else {
      status = true;
      if (eyeIcon) {
        eyeIcon.src = visibleIcon;
      }

      if (currentBox) {
        currentBox.style.display = "block";
      }

      // Used to open eyeIcon when infinite scrolling
      this.track.updateCurrentlyVisible(true);
    }

    const video = document.getElementById("video");
    const currentFrameID = Math.floor(this.props.fps * video.currentTime);
    this.track.setVisibility(currentFrameID, status, this.props.num_frames);
    this.props.updateUI();
  }

  Click() {
    TrackCollection.tracks.map((track) => {
      if (track.selected === true) {
        const redactionBox = document.getElementById("redaction-object" + track.id);
        if (redactionBox) redactionBox.classList.remove("trackobject-selected");
      }
      return 1;
    });

    const handle = document.getElementById(`redaction-object${this.track.id}`);
    if (handle) {
      const arr = handle.className.split(" ");
      if (arr.indexOf("trackobject-selected") === -1) {
        handle.className += " trackobject-selected";
      }
    }

    const video = document.getElementById("video");
    if (video && video.paused) {
      this.props.setFrame(this.track.firstVisibleFrameID);
    }
    let firstVisibleFrameID = this.track.firstVisibleFrameID;

    const allVisibleFrames = [];
    for (let frame in this.track.boxes) {
      if (this.track.boxes[frame].visible) {
        allVisibleFrames.push(frame);
      }
    }

    firstVisibleFrameID = Math.min(...allVisibleFrames);

    if (firstVisibleFrameID === Infinity || isNaN(firstVisibleFrameID)) {
      firstVisibleFrameID = 1;
    }

    const new_time = firstVisibleFrameID / this.props.fps + 0.00001;

    if (!isNaN(new_time)) {
      video.currentTime = new_time;
    }

    const seekBar = document.getElementById("seek-bar");
    seekBar.value = (new_time / video.duration) * 100;

    if (this.props.wavesurfer && this.props.wavesurfer.getDuration() > 0) {
      this.props.wavesurfer.seekTo(new_time / video.duration);
    }

    TrackCollection.selectTrack(this.track.id);
  }

  render() {
    return (
      <div className="trackobject trackobjectfoldeddown" id={"redaction-object" + this.track.id}>
        <div />
        <div style={{ float: "right" }}>
          <div className="img-frame" id={"box_border" + this.track.id}>
            <img alt="Error" src={spacer} style={{ width: 12 }} />
          </div>
          <div
            className="ui-icon ui-icon-closethick eye"
            id={"trackobject_" + this.track.id + "_visible"}
            title="Object not visible"
          >
            <img
              alt="Error"
              id={"trackobject_" + this.track.id + "_visible_icon"}
              className="not-visible-icon"
              src={notVisibleIcon}
              onClick={this.toggleDisplay}
            />
          </div>
          <div
            className="ui-icon ui-icon-closethick del"
            id={"trackobject" + this.track.id + "delete"}
            title="Delete this object path"
            onClick={this.delete}
          >
            <img alt="Error" id={"delete_" + this.track.id + "_button"} className="delete-icon" src={deleteIcon} />
          </div>
        </div>
        <p className="trackobjectheader" onMouseUp={this.Click}>
          <strong>
            {this.track.label}
            {this.track.type === "manual" && <sup>*</sup>}
          </strong>
        </p>
        <div className="trackobjectdetails" />
      </div>
    );
  }
}
