/*
 * Tracks
 *   - Track  - Automated || Manual
 *      - category - Face || Person || Custom
 *      - type: manual / automated
 *      - boxes: [[frameid : rect, visible (true / false)]] - each box is uniquely owned by each track
 *      - notes: ''
 */

/*
    1. Automated track contains frames with detection, each frame acts as key frame with visibility on
    2. Manual track contains key frames with visibility ON / OFF
    3. Each track object contains list of object w.r.t to frame
*/

const { videoDisplayResolution } = require('../../../config/globals');

export class TracksCollection {
  constructor(tracks, numFrames) {
    this.tracks = tracks;
    this.numFrames = numFrames;
  }

  getTrack(trackID) {
    return this.tracks.find((track) => track.id === parseInt(trackID, 10));
  }

  getTracks() {
    return this.tracks;
  }

  merge(mtracks) {
    // @TODO : Handle invalid mTracks case
    if (mtracks.length <= 1) {
      return;
    }

    // Merging Auto track first, second is manual
    // Merging Manual track first, second is Auto
    const mergedTrack = mtracks[0];
    for (let idx = 1; idx < mtracks.length; idx++) {
      for (let frameID in mtracks[idx].boxes) {
        // If previous track has a box w.r.t frameID then ignore current track box
        if (!mergedTrack.boxes[frameID]) {
          mergedTrack.insert(frameID, mtracks[idx].boxes[frameID].clone());
        }
      }
    }

    // Delete merged tracks
    for (let idx = 1; idx < mtracks.length; idx++) {
      this.delete(mtracks[idx].id);
    }

    // |                | (M - off)
    //    |    |  (M - off)

    // |                |
    //       |

    //       |
    // |                |

    // M + A
    // |     | M off
    //       | A - On

    //  A + M
    //       | (Auto visible)
    // |     | (Manual not visible)
  }

  // Convert frontdata data to backend data
  serialize(scaleRatio, numFrames) {
    const backendData = {};

    for (let i = 0; i <= numFrames; i++) {
      const boxCollection = this.getBoxes(i, this.tracks, numFrames);
      const trackIDs = Object.keys(boxCollection);
      for (let trackID of trackIDs) {
        if (!backendData[trackID]) {
          backendData[trackID] = [];
        }

        backendData[trackID].push([
          boxCollection[trackID].xtl * scaleRatio,
          boxCollection[trackID].ytl * scaleRatio,
          boxCollection[trackID].xbr * scaleRatio,
          boxCollection[trackID].ybr * scaleRatio,
          i,
          boxCollection[trackID].visible,
        ]);
      }
    }

    return backendData;
  }

  // Convert Backend data to Frontend data
  deserialize(backendData, scaleRatio, manualRedactions = null) {
    const frontendData = {
      objects_info: {},
      manual_redaction: {
        0: {},
        1: {},
        2: {},
      },
    };

    // Assigning manualRedactions if exist
    if (manualRedactions) {
      frontendData.manual_redaction = manualRedactions;
    }

    // Manual redaction object id is assumed to be started from 10000
    const manualRedactionObjectIDStart = 10000;

    const objectIDs = Object.keys(backendData);
    for (let objectID of objectIDs) {
      if (objectID < manualRedactionObjectIDStart) {
        const objectData = backendData[objectID];
        const [x1, y1, x2, y2, frameID, visibility] = objectData;

        if (!frontendData.objects_info[objectID]) {
          frontendData.objects_info[objectID] = [];
        }

        frontendData.objects_info[objectID].push([
          x1 * scaleRatio,
          y1 * scaleRatio,
          x2 * scaleRatio,
          y2 * scaleRatio,
          frameID, // frame which the box exist
          visibility, // visibility of the box,
        ]);
      }
    }

    return frontendData;
  }

  addNew(track) {
    // @TOOD add a check for whether it is valid or not
    this.tracks.push(track);
  }

  selectTrack(trackID) {
    // Set the track with trackID to selected
    for (let track of this.tracks) {
      if (track.id === trackID) {
        track.selected = true;
      } else {
        track.selected = false;
      }
    }
  }

  // See if flag is required
  delete(trackID) {
    // @TODO: Do not delete, keep flag to check the deletion status
    // Find the index of track which matches with track id and delete
    const index = this.tracks.findIndex((track) => track.id === trackID);
    this.tracks.splice(index, 1);
  }

  getTrackBox(track, frameID) {
    const trackBoxes = track.boxes; // {frameID : box} KV pair

    // @TODO use each track boundary frames to skip the check
    let b = null;
    if (trackBoxes[frameID]) {
      b = trackBoxes[frameID];
    } else {
      b = track.getInterpolatedBox(frameID, this.numFrames);
    }

    // Add if box is valid and visible
    if (b && b.visible) {
      return b;
    }

    return null;
  }

  /*
   * Get objects associated with frame
   * Each trackID should contain only one object per frame
   * Each frame auto detection is considered as a key frame
   * For manual frames - left and right key frames are used to estimate object position
   * @TODO : There is no good way to handle if automated track is inserted with manual boxes
   */
  getBoxes(frameID) {
    // Boxes which needs to be rendered on frame
    const frameBoxes = {};

    for (let idx = 0; idx < this.tracks.length; idx++) {
      // Iterate over each and boxes associate with each frame
      // Handle auto and manual boxes
      const track = this.tracks[idx];
      const trackBoxes = track.boxes; // {frameID : box} KV pair

      // @TODO use each track boundary frames to skip the check
      let b = null;
      if (trackBoxes[frameID]) {
        b = trackBoxes[frameID];
      } else {
        b = track.getInterpolatedBox(frameID, this.numFrames);
      }

      // Add if box is valid and visible
      if (b && b.visible) {
        frameBoxes[track.id] = b;
      }
    }

    return frameBoxes;
  }

  clear() {
    this.tracks = [];
    this.numFrames = null;
  }

  /*
   * @TODO revisit it later
   * Return cached frame objects if the frame is revisited without updates
   * LRU cache
   */
  getCachedBoxes() {}
}

export class Track {
  constructor(id, name, type, color, box, frameID, notes) {
    this.id = id;
    this.label = name || ''; // face || person || vechicle || custom
    this.notes = notes || ''; //
    this.type = type; // manual || auto

    // @TODO - Discuss with Srikanth
    this.objectType = ''; // 'face' || 'body'
    this.labelid = ''; // ID for continue from file retrieval
    this.color = color;
    this.selected = false;
    this.currentlyVisible = false;
    this.startFrame = 0;
    this.firstVisibleFrameID = null;
    this.lastNonVisibleFrameID = null;

    // Collection with frameid as key, initialize the dictionary
    this.boxes = {};

    this.insert(frameID, box);
  }

  updateTrackBounds(frameID) {
    const visible = this.boxes[frameID].visible;

    // Update left extreme
    if (visible && (this.firstVisibleFrameID === null || this.firstVisibleFrameID > frameID)) {
      this.firstVisibleFrameID = frameID;
    }

    // Update right extreme
    if (!visible && (this.lastNonVisibleFrameID === null || this.lastNonVisibleFrameID < frameID)) {
      this.lastNonVisibleFrameID = frameID;
    }
  }

  /*
   * For automated boxes return interpolated frame if there is a valid right frame
   */
  getInterpolatedBox(frameID, numFrames) {
    // Get nearest left and right boxes for a given frameID
    let leftFrameID = 0;
    const artificialFrameID = numFrames + 1;
    let rightFrameID = artificialFrameID; // Set it to out of video

    // Find left and right boundary
    for (const key in this.boxes) {
      const frameIDKey = parseInt(key);
      if (frameIDKey < frameID && leftFrameID < frameIDKey) {
        leftFrameID = frameIDKey;
      }

      if (frameIDKey > frameID && rightFrameID > frameIDKey) {
        rightFrameID = frameIDKey;
      }
    }

    // Return if nearest left keyframe box is set to not visible
    if (!this.boxes[leftFrameID].visible) {
      return null;
    }

    // Skip interpolation if track is automated and left frame id box not manual
    if (this.type === 'auto' && (rightFrameID === artificialFrameID || !this.boxes[leftFrameID].isManual)) {
      return null;
    }

    // Return if there is no right key frame
    if (rightFrameID === artificialFrameID) {
      return this.boxes[leftFrameID].clone();
    }

    // Both left and right key frames exist
    // Estimate interpolation, 1 to avoid numerical exception
    const fdiff = rightFrameID - leftFrameID + 1;

    var xtlr = (this.boxes[rightFrameID].xtl - this.boxes[leftFrameID].xtl) / fdiff;
    var ytlr = (this.boxes[rightFrameID].ytl - this.boxes[leftFrameID].ytl) / fdiff;
    var xbrr = (this.boxes[rightFrameID].xbr - this.boxes[leftFrameID].xbr) / fdiff;
    var ybrr = (this.boxes[rightFrameID].ybr - this.boxes[leftFrameID].ybr) / fdiff;

    var off = frameID - leftFrameID;
    var xtl = this.boxes[leftFrameID].xtl + xtlr * off;
    var ytl = this.boxes[leftFrameID].ytl + ytlr * off;
    var xbr = this.boxes[leftFrameID].xbr + xbrr * off;
    var ybr = this.boxes[leftFrameID].ybr + ybrr * off;

    const b = new Box(xtl, ytl, xbr, ybr, this.boxes[leftFrameID].visible);
    // Return box if it's nearest left box is set to visible
    return b;
  }

  getNearestLeftBox(frameID) {
    // Guard - if current frameID has a box
    if (this.boxes[frameID]) {
      return this.boxes[frameID];
    }

    // Get nearest frame id
    let nearestFrameID = 0;
    for (const curFrameID in this.boxes) {
      if (curFrameID > frameID) {
        continue;
      }

      if (nearestFrameID < curFrameID) {
        nearestFrameID = curFrameID;
      }
    }

    return this.boxes[nearestFrameID].clone();
  }

  // Helper function to get nearest left and right frame ids for a given frame ID
  getBounds(frameID) {
    let leftFrameID = 0;
    let rightFrameID = this.type === 'auto' ? null : 100000000;

    for (const curFrameID in this.boxes) {
      // No op
      if (curFrameID === frameID) {
        continue;
      }

      // Assign left frame ID
      if (curFrameID < frameID && curFrameID > leftFrameID) {
        leftFrameID = curFrameID;
      }

      // Assign right frame ID
      if (curFrameID > frameID && (rightFrameID === null || curFrameID < rightFrameID)) {
        rightFrameID = curFrameID;
      }
    }

    return { leftFrameID, rightFrameID };
  }

  /*
   * Insert / update a box in frame
   */
  insert(frameID, box) {
    // @TODO - for now store boxes in list based on frame ID
    // Flatten boxes dict to sotred list, insert the box
    // Reconstruct the dicitonary

    this.boxes[frameID] = box;

    if (!this.boxes[this.startFrame]) {
      this.boxes[this.startFrame] = new Box(box.xtl, box.ytl, box.xbr, box.ybr, false);
    }

    this.updateTrackBounds(frameID);
  }

  // Used by frontend to check whether the track is currently visible or not for updating eyeIcon
  updateCurrentlyVisible(status = false) {
    this.currentlyVisible = status;
  }

  setVisibility(frameID, status, numFrames) {
    if (this.boxes[frameID]) {
      this.boxes[frameID].visible = status;
      this.updateTrackBounds(frameID);
      return;
    }

    /*
    if (this.type === "auto") {
      this.boxes[frameID] = this.getNearestLeftBox(frameID);
    } else if (this.type === "manual") {
      let b = this.getInterpolatedBox(frameID, numFrames);
      if (!b) {
        b = this.getNearestLeftBox(frameID);
      }
      this.boxes[frameID] = b;
    }
    */

    // Mark the box as manual and inject into the track
    let b = this.getInterpolatedBox(frameID, numFrames);
    if (!b) {
      b = this.getNearestLeftBox(frameID);
    }
    this.boxes[frameID] = b;

    this.boxes[frameID].visible = status;
    this.boxes[frameID].isManual = true;
    this.updateTrackBounds(frameID);
  }

  /*
   * Update existing box information of a given frame ID
   */
  update(frameID, box) {
    this.boxes[frameID] = box;
    this.updateTrackBounds(frameID);

    return;

    /*  
    ! Do Not Smooth frames
    */
    // const prevBox = this.boxes[frameID];
    // Return if box is manual type or no previous frame box
    // if (this.type === 'manual' || !prevBox) {
    //   return;
    // }

    // Return if box coordinates are not changed
    // if (box.xtl === prevBox.xtl && box.ytl === prevBox.ytl && box.xbr === prevBox.xbr && box.ybr === prevBox.ybr) {
    //   return;
    // }

    // Smooth nearby automated frames +- 15 frames each side
    // Cacluate percentage of change for each coordinate point
    // Adjust same set of changes to neighboring frames
    // const dxtl = (box.xtl - prevBox.xtl) / parseFloat(prevBox.xtl);
    // const dytl = (box.ytl - prevBox.ytl) / parseFloat(prevBox.ytl);
    // const dxbr = (box.xbr - prevBox.xbr) / parseFloat(prevBox.xbr);
    // const dybr = (box.ybr - prevBox.ybr) / parseFloat(prevBox.ybr);

    // for (const nearFrameID of Object.keys(this.boxes)) {
    // Skip current frame and far frames
    // if (nearFrameID - frameID != 1 || nearFrameID - frameID != 2) {
    //   continue;
    // }

    // let nearFrameBox = this.boxes[nearFrameID];
    // Continue if boxes are not overlapping
    // const overlap = Math.abs(iou(prevBox, nearFrameBox));
    // if (overlap < 0.6) {
    //   continue;
    // }

    // const xtl = Math.round(nearFrameBox.xtl + nearFrameBox.xtl * dxtl);
    // const ytl = Math.round(nearFrameBox.ytl + nearFrameBox.ytl * dytl);
    // const xbr = Math.round(nearFrameBox.xbr + nearFrameBox.xbr * dxbr);
    // const ybr = Math.round(nearFrameBox.ybr + nearFrameBox.ybr * dybr);

    // this.boxes[nearFrameID].updateBox(xtl, ytl, xbr, ybr);
    // }
  }
}

export class Box {
  // @TODO add math function to fix out of bounds coordinates
  constructor(xtl, ytl, xbr, ybr, visible, isManual = false) {
    this.xtl = Math.round(xtl);
    this.ytl = Math.round(ytl);
    this.xbr = Math.round(xbr);
    this.ybr = Math.round(ybr);
    this.width = Math.round(xbr - xtl);
    this.height = Math.round(ybr - ytl);
    this.visible = visible;
    this.isManual = isManual;
    this.validate();
  }

  clone() {
    return new Box(this.xtl, this.ytl, this.xbr, this.ybr, this.visible, this.isManual);
  }

  updateRect(x, y, w, h) {
    this.xtl = x;
    this.ytl = y;
    this.xbr = x + w;
    this.ybr = y + h;
    this.width = w;
    this.height = h;
    this.validate();
  }

  updateBox(xtl, ytl, xbr, ybr) {
    this.xtl = xtl;
    this.ytl = ytl;
    this.xbr = xbr;
    this.ybr = ybr;
    this.width = xbr - xtl;
    this.height = ybr - ytl;
    this.validate();
  }

  toggle() {
    this.visible = !this.visible;
  }

  validate() {
    this.xtl = Math.max(0, this.xtl);
    this.ytl = Math.max(0, this.ytl);
    this.xbr = Math.min(this.xbr, videoDisplayResolution.width - 3);
    this.ybr = Math.min(this.ybr, videoDisplayResolution.height - 3);
  }
}

export function iou(box1, box2) {
  let overlap = 0.0;
  if (!box1 || !box2) {
    return overlap;
  }

  const inter =
    (Math.min(box1.xbr, box2.xbr) - Math.max(box1.xtl, box2.xtl)) *
      (Math.min(box1.ybr, box2.ybr) - Math.max(box1.ytl, box2.ytl)) +
    1.0;
  const area1 = Math.abs(box1.ybr - box1.ytl) * Math.abs(box1.xbr - box1.xtl);
  const area2 = Math.abs(box2.ybr - box2.ytl) * Math.abs(box2.xbr - box2.xtl);
  const union = area1 + area2 - inter + 1.0;

  overlap = inter / union;
  return overlap;
}
