import axios from 'axios';

import { host } from '../../../config/globals';

async function makeRequest(method, url, headers = {}, data = {}, config = {}) {
  try {
    const response = await axios({
      method,
      url,
      headers,
      data,
      ...config,
    });

    return { status: response.status, ...response.data };
  } catch ({ response }) {
    if (response) {
      return { status: response.status, ...response.data };
    }
    return { error: 'Network failure' };
  }
}

export function fetchMetadata(jobID) {
  return makeRequest('GET', `${host()}/suspect/metadata/videodata`, { jobid: jobID });
}

export function fetchTracks(jobID, type) {
  return makeRequest('GET', `${host()}/suspect/metadata`, { jobid: jobID, type: type });
}

export function saveTracks(jobID, tracks, scaleRatio) {
  return makeRequest(
    'POST',
    host() + '/suspect/metadata',
    {
      jobid: jobID,
    },
    {
      tracks,
      scaleRatio,
    },
  );
}

export function fetchMutedRegions(jobID) {
  return makeRequest('GET', `${host()}/suspect/metadata/audio`, {
    jobid: jobID,
  });
}

export function saveMutedRegions(jobID, mutedRegions) {
  return makeRequest(
    'POST',
    host() + '/suspect/metadata/audio',
    {
      jobid: jobID,
    },
    {
      mutedRegions,
    },
  );
}

export function fetchSettings(jobID) {
  return makeRequest('GET', `${host()}/suspect/metadata/settings`, { jobid: jobID });
}

export function saveSettings(settings, jobID) {
  return makeRequest('POST', `${host()}/suspect/metadata/settings`, { jobid: jobID }, { settings });
}

export function doRedaction(jobID, type) {
  return makeRequest('POST', `${host()}/suspect/redact`, { jobid: jobID, type: type });
}

export function fetchRedactProgress(jobID, type) {
  return makeRequest('GET', `${host()}/suspect/redact`, {
    jobid: jobID,
    type: type,
  });
}
