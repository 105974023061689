import React, { Component } from "react";
import $ from "jquery";

import { host } from "../../config/globals";

const path = require("path");
const { formattedTimeSMPTE } = require("./HelperComponents/toSMPTE");

export default class SliderBar extends Component {
  constructor(props) {
    super(props);
    this.video = undefined;
    this.fps = undefined;
    this.seek_bar = undefined;
    this.imgPath = undefined;
    this.time_elapsed = undefined;
    this.jpg_overlay_handle = undefined;
    this.updateSeekbar = this.updateSeekbar.bind(this);
    this.move_to_frame = this.move_to_frame.bind(this);
  }

  componentDidMount() {
    this.jpg_overlay_handle = $("#video_jpg_overlay");
    this.fps = this.props.fps;
    this.time_elapsed = document.getElementById("time-elapsed");
    this.video = document.getElementById("video");
    this.seek_bar = document.getElementById("seek-bar");
    this.seek_bar.value = 0;
    this.seek_bar.disabled = true;

    let value = 100 * (this.video.currentTime / this.video.duration);
    if (!isNaN(value)) {
      this.seek_bar.value = value;
    }

    this.hoverFrame();
    this.updateSeekbar();
    this.timeupdateListener();
  }

  componentDidUpdate() {
    this.fps = this.props.fps;
    if (this.props.is_video_loaded) this.seek_bar.disabled = false;
    if (this.props.metadata) {
      this.imgPath = path.join(this.props.metadata, "imgs/");
    }
  }

  componentWillUnmount() {
    if (this.playInterval) {
      clearInterval(this.playInterval);
    }
  }

  hoverFrame() {
    const slider_preview = document.getElementById("slider-preview");
    const slider_preview_ts = document.getElementById("slider-preview-ts");
    const v = document.getElementById("video");

    this.seek_bar.addEventListener("mousemove", (e) => {
      if (this.props.is_video_loaded && e.offsetX <= e.target.clientWidth) {
        let value_hover = (e.offsetX / e.target.clientWidth) * 100;
        let time = (value_hover * this.video.duration) / 100;
        let frame = Math.floor(time * this.fps);
        if (frame <= 0) frame = 1;
        if (frame > this.props.num_frames) frame = this.props.num_frames;

        value_hover = parseInt(value_hover);
        this.imgPath = path.join(this.props.metadata, "imgs/");
        if (!isNaN(frame) || frame > 0) {
          const url = new URL(`${host()}/suspect/static${this.props.metadata}/imgs/${frame}.jpg`);
          $("#slider-preview").css("background-image", `url(${url})`);
        }

        slider_preview.style.backgroundSize = "300px 113px";

        slider_preview.style.top = v.height + -120 + "px";
        slider_preview.style.left = e.pageX - 200 / 2 + "px";
        slider_preview.style.display = "block";

        slider_preview_ts.style.top = v.height + -20 + "px";
        slider_preview_ts.style.left = e.pageX - 80 / 2 + "px";
        slider_preview_ts.style.display = "block";

        // console.log(e.offsetX )
        // let frame = Math.floor( (e.offsetX) / e.target.clientWidth * this.video.duration * this.fps )
        let ts = formattedTimeSMPTE(frame, this.fps);
        ts = ts.substring(0, ts.indexOf("."));
        slider_preview_ts.innerHTML = ts;
      }
    });

    this.seek_bar.addEventListener("mouseout", () => {
      slider_preview.style.display = "none";
      slider_preview_ts.style.display = "none";
    });
  }

  updateSeekbar() {
    const pos = this.seek_bar.value;
    if (pos === 0) {
      this.time_elapsed.innerHTML = "0:00:00.00 / 0:00:00.00";
    }
    if (pos === 10) {
      this.time_elapsed.innerHTML = this.video.duration + "/" + this.video.duration;
    }
    let new_time = (pos / 100) * this.video.duration;
    if (!isNaN(new_time)) {
      this.video.currentTime = new_time;
    }

    if (this.props.wavesurfer && this.props.wavesurfer.getDuration() > 0) {
      this.props.wavesurfer.seekTo(pos / 100);
    }

    if (this.video.paused) {
      let frame_seek = Math.floor(new_time * this.fps);
      this.move_to_frame(frame_seek);
    }
  }

  move_to_frame(frame) {
    if (isNaN(frame) || frame <= 0 || frame > this.props.num_frames) {
      return;
    }
    let url = new URL(host() + "/image/");
    url.searchParams.append("jobId", this.props.jobId);
    url.searchParams.append("company", "zepcam");
    url.searchParams.append("frame", this.imgPath + frame + ".jpg");
    this.jpg_overlay_handle.attr("src", `url(${url})`);
  }

  moveSeekbar = () => {
    const value = (this.video.currentTime / this.video.duration) * 100;
    if (!isNaN(value)) {
      this.seek_bar.value = value;
    }
  };

  timeupdateListener() {
    this.video.addEventListener("play", () => {
      this.playInterval = setInterval(() => {
        if (this.video.paused) {
          clearInterval(this.playInterval);
        } else {
          this.moveSeekbar();
        }
      }, 1);
    });

    this.video.addEventListener("timeupdate", () => {
      if (this.video.paused) {
        this.moveSeekbar();
      }
    });
  }

  render() {
    return (
      <div id="flag_pointer_container">
        <input type="range" step={0.1} className="vievu-slider" id="seek-bar" onChange={this.updateSeekbar} />
      </div>
    );
  }
}
