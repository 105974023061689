import React from 'react';
import 'rc-notification/assets/index.css';
import Notification from 'rc-notification';
import { v4 as uuid } from 'uuid';

import SuccessNotification from './SuccessNotification';
import WarningNotification from './WarningNotification';
import ErrorNotification from './ErrorNotification';

let notification = null;

// Create  a new notification instance
Notification.newInstance(
  {
    style: { top: 50, right: 0, zIndex: 9999999 }
  },
  n => {
    notification = n;
  }
);

const notificationStyle = {
  padding: 0,
  marginBottom: 5,
  border: 'none',
  boxShadow: 'none'
};

const closeNotification = key => {
  notification.removeNotice(key);
};

export const successNotification = title => {
  const key = uuid();
  notification.notice({
    content: (
      <SuccessNotification
        title={title}
        notifId={key}
        close={closeNotification}
      />
    ),
    duration: 3,
    key,
    style: notificationStyle
  });
};

export const errorNotification = title => {
  const key = uuid();
  notification.notice({
    content: (
      <ErrorNotification
        title={title}
        notifId={key}
        close={closeNotification}
      />
    ),
    duration: 3,
    key,
    style: notificationStyle
  });
};

export const warningNotification = title => {
  const key = uuid();
  notification.notice({
    content: (
      <WarningNotification
        title={title}
        notifId={key}
        close={closeNotification}
      />
    ),
    duration: 3,
    key,
    style: notificationStyle
  });
};
